import React from "react";
import Header from "./header";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import $ from "jquery";
import { BASE_URL } from "./config";

const SakhiCreateloan = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOption1, setSelectedOption1] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("");

  const [options, setOptions] = useState([]);
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDate1, setSelectedDate1] = useState("");
  const [selectedDate2, setSelectedDate2] = useState("");

  const [ltype, setLtype] = useState("NEW");
  const [isChecked, setIsChecked] = useState(false);
  const [message, setMessage] = useState("");
  const [message1, setMessage1] = useState("");
  const [allowed, setAllowed] = useState(false);

  const handleDateChange = (options) => {
    setSelectedDate(options.value);
    var id = options.value.split(") ");
    setSelectedOption(id[0]);
    var aa = id[1].split("-");
    setMessage1(aa[1]);
    if (aa[0] < 12) {
      setAllowed(true);
    }
    setMessage(
      "Total Paid Month :" + aa[0] + "   Paid Amount : " + aa[0] * 200
    );
  };

  const selectWit1 = (options) => {
    setSelectedDate1(options.value);
    var id = options.value.split(") ");
    setSelectedOption1(id[0]);
  };

  const selectWit2 = (options) => {
    setSelectedDate2(options.value);
    var id = options.value.split(") ");
    setSelectedOption2(id[0]);
  };

  const initialState = {
    iamount: "",
    month: "",
    emi: "",
    loan: "",
    l_ty: "NEW",
  };

  const [state, setState] = useState(initialState);
  const { iamount, month, emi, loan, l_ty } = state;

  let name, value;
  const handler = (e) => {
    name = e.target.name;
    value = e.target.value;
    setState({ ...state, [name]: value });
  };
  const [tableData, setTableData] = useState([]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const dObject = {
      authorization: token,
      input: { page: "SakhiCreateMember" },
    };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(BASE_URL + "admin_select_user.php", deleteData)
      .then((data) => {
        //console.log(data);
        const optionsData = data.data.result.map((item) => ({
          value: item.id + ")  " + item.installment_count + "-" + item.name,
          label: item.id + ")  " + item.name,
        }));
        setOptions(optionsData);
      })
      .catch((error) => console.error(error));
    witnessData();
    Web();
  }, []);

  const witnessData = () => {
    const token = localStorage.getItem("token");
    const dObject = {
      authorization: token,
      input: { page: "SakhiCreateMember" },
    };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(BASE_URL + "admin_select_user.php", deleteData)
      .then((data) => {
        //console.log(data);
        const optionsData1 = data.data.result.map((item) => ({
          value: item.id + ")  " + item.name,
          label: item.id + ")  " + item.name,
        }));
        const optionsData2 = data.data.result.map((item) => ({
          value: item.id + ")  " + item.name,
          label: item.id + ")  " + item.name,
        }));
        setOptions1(optionsData1);
        setOptions2(optionsData2);
      })
      .catch((error) => console.error(error));
  };

  var a_name = localStorage.getItem("user");
  var aid = localStorage.getItem("aid");
  var pass = localStorage.getItem("pass");

  const web = localStorage.getItem("web");

  const Web = () => {
    const token = localStorage.getItem("token");
    const dObject = {
      authorization: token,
      input: {
        aid: aid,
        auth: web,
      },
    };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(BASE_URL + "web_auth.php", deleteData)
      .then(function (data) {
        const abc = data.data.web;
        if (abc !== web) {
          localStorage.setItem("user", "");
          localStorage.setItem("token", "");
          localStorage.setItem("pass", "");
          localStorage.setItem("aid", "");
          localStorage.setItem("margin", "");
          localStorage.setItem("web", "");
          toast.error("You can not use on multiple device at a same time", {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload(false);
        }
        //console.log(data)
      })
      .catch((error) => console.error(error));
  };

  const handleCheck = (e) => {
    // //console.log(e.target.checked)
    setIsChecked(e.target.checked);
    if (e.target.checked == true) {
      setLtype("OLD");
      // state.l_ty = "OLD";
    } else {
      setLtype("NEW");

      // state.l_ty = "NEW";
    }
  };

  var total = parseInt(loan) + parseInt(iamount);

  const Check = (e) => {
    //console.log(ltype);

    e.preventDefault();
    $(".btn").addClass("disabled");
    const token = localStorage.getItem("token");
    const dObject = {
      authorization: token,
      input: {
        interest: iamount,
        sid: selectedOption,
        loan_amount: loan,
        a_name: a_name,
        aid: aid,
        auth: pass,
        s1:selectedOption1,
        s2:selectedOption2,
        month: month,
        emi: emi,
        remaining_month: month,
        remaining: loan,
        loan_type: ltype,
      },
    };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(BASE_URL + "admin_sakhi_create_loan.php", deleteData)
      .then(function (data) {
        //console.log(data)
        if (data.data.message === "radha") {
          setState({ iamount: "", emi: "", month: "", loan: "" });
          setSelectedDate("");
          setLtype("NEW");
          setIsChecked(false);
          toast.success("Loan Created", { position: toast.POSITION.TOP_RIGHT });
          $(".btn").removeClass("disabled");
          setSelectedOption("");
          setSelectedOption1("");
          setSelectedOption2("");
          setSelectedDate("");
          setSelectedDate1("");
          setSelectedDate2("");
          setMessage("");
          setMessage1("")
        } else {
          toast.error("Loan Not Created", {
            position: toast.POSITION.TOP_RIGHT,
          });
          $(".btn").removeClass("disabled");
        }
      });
  };

  const margin = localStorage.getItem("margin");

  return (
    <div>
      <div class="page-body-wrapper">
        <div class="page-body" style={{ marginLeft: "320px" }}>
          <div class="container-fluid">
            <div class="page-title">
              <div class="row">
                <ToastContainer />
                <div class="col-sm-6">
                  <h3>Sakhi Saheli Create Loan</h3>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to={"/"}>
                        {" "}
                        <i data-feather="home"></i>Dashboard
                      </Link>
                    </li>
                    <li class="breadcrumb-item">Sakhi Saheli Create Loan</li>
                    {/* <li class="breadcrumb-item active">Create Loan</li> */}
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="select2-drpdwn">
              <div class="row">
                <div class="col-md-12">
                  <div class="card" style={{ minHeight: "300px" }}>
                    <div class="card-header pb-0">
                      <h5 class="card-title">Sakhi Saheli Create Loan</h5>
                    </div>
                    <div class="card-body o-hidden">
                      <form onSubmit={Check}>
                        {/* <div class="mb-2">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" checked={isChecked} name="oldloan" id="flexCheckChecked" onChange={handleCheck} />
                                                        <label class="form-check-label" for="flexCheckChecked">
                                                            OLD Loan
                                                        </label>
                                                    </div>
                                                </div> */}
                        {/* <input class="form-control" type="text" onChange={(e) => setPname(e.target.value)} onBlur={search} /> */}
                        <div class="mb-2">
                          <div class="col-form-label">Select Name</div>
                          <Select
                            value={selectedDate}
                            onChange={handleDateChange}
                            options={options}
                            isSearchable={true}
                          />
                        </div>
                        <div class="mb-2">
                          <h5>{message1}</h5>
                          <p>{message}</p>
                        </div>
                        {allowed && (
                          <>
                            {" "}
                            <div className="row"></div>
                            <div class="mb-2">
                              <label for="name">Witness 1</label>

                              <Select
                                value={selectedDate1}
                                onChange={selectWit1}
                                options={options1}
                                isSearchable={true}
                              />
                              {selectedDate1}
                            </div>
                            <div class="mb-2">
                              <label for="name">Witness 2</label>

                              <Select
                                value={selectedDate2}
                                onChange={selectWit2}
                                options={options2}
                                isSearchable={true}
                              />
                              {selectedDate2}
                            </div>
                            <div class="mb-2">
                              <label for="name">Loan Amount</label>
                              <input
                                class="form-control"
                                id="name"
                                type="text"
                                value={state.loan}
                                name="loan"
                                onChange={handler}
                                required="required"
                                onKeyDownCapture={handleKeyPress}
                              />
                            </div>
                            <div class="mb-2">
                              <label for="contact">EMI Amount</label>
                              <input
                                class="form-control"
                                type="text"
                                name="emi"
                                value={state.emi}
                                onChange={handler}
                                required="required"
                                onKeyDownCapture={handleKeyPress}
                              />
                            </div>
                            <div class="mb-2">
                              <label for="contact">Loan Month</label>
                              <input
                                class="form-control"
                                type="text"
                                name="month"
                                value={state.month}
                                onChange={handler}
                                required="required"
                                onKeyDownCapture={handleKeyPress}
                              />
                            </div>
                            <div class="mb-3">
                              <label for="contact">Interest</label>
                              <input
                                class="form-control"
                                type="text"
                                name="iamount"
                                value={state.iamount}
                                onChange={handler}
                                required="required"
                                onKeyDownCapture={handleKeyPress}
                              />
                            </div>
                            <div class="mb-2">
                              <button
                                class="btn btn-primary"
                                onKeyDown={handleKeyPress}
                                type="submit"
                              >
                                Create Loan
                              </button>
                            </div>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SakhiCreateloan;
