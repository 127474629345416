import React from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "./sidebar";
import { Link, NavLink } from "react-router-dom";

import $ from "jquery";
import { useEffect, useState } from "react";
import {
  Cast,
  ChevronDown,
  ChevronRight,
  Clock,
  Codepen,
  Delete,
  FilePlus,
  Layers,
  Layout,
  MoreHorizontal,
  Package,
  ShoppingBag,
  Slack,
  Unlock,
  User,
  UserCheck,
  UserMinus,
  UserX,
} from "react-feather";
import { Monitor } from "react-feather";
import { Mail } from "react-feather";
import { MessageCircle } from "react-feather";
import { Book } from "react-feather";
import { Users } from "react-feather";
import { UserPlus } from "react-feather";
import { Edit } from "react-feather";
import { Heart } from "react-feather";
import { DollarSign } from "react-feather";
import { List } from "react-feather";
import { FileText } from "react-feather";
import { Server } from "react-feather";
import { GitPullRequest } from "react-feather";
import { ArrowDown } from "react-feather";

const SakhiHeader = () => {
  const history = useHistory();
  const [status, setStatus] = useState("");
  const [loandata, setLoandata] = useState(false);

  const Logout = () => {
    localStorage.setItem("user", "");
    localStorage.setItem("token", "");
    localStorage.setItem("pass", "");
    localStorage.setItem("aid", "");
    localStorage.setItem("margin", "");
    localStorage.setItem("web", "");
    window.location.reload(false);
    // setTimeout(() => history.push("/"), 250);
  };

  //console.log(localStorage.getItem('aid'))

  if (
    localStorage.getItem("aid") == null ||
    localStorage.getItem("aid") == ""
  ) {
    history.push("/"); // #8b0000irect to login page
  }

  var sesspass = localStorage.getItem("user");

  const Dark = () => {
    $(".page-header").removeClass("close_icon");
    $(".sidebar-wrapper").removeClass("close_icon");
  };

  const Open = () => {
    var abc = document.getElementById("page-header").className;
    const currentURL = window.location.href;
    //console.log(abc);
    const aaa = currentURL.split("/");
    const url = aaa[3];

    if (abc == "page-header container-fluid close_icon") {
      //console.log("close");
      localStorage.setItem("margin", "320px");
      $(".page-header").removeClass("close_icon");
      $(".sidebar-wrapper").removeClass("close_icon");
      $(".page-body").removeClass("side");
      setTimeout(() => history.push("/" + url), 0);
    } else {
      //console.log("open");

      localStorage.setItem("margin", "106px");
      $(".page-body").addClass("side");
      $(".page-header").addClass("close_icon");
      $(".sidebar-wrapper").addClass("close_icon");
      setTimeout(() => history.push("/" + url), 0);
    }
  };
  const margin = localStorage.getItem("margin");
  // useEffect(() => {
  //   var modes = localStorage.getItem("mode");
  //   setStatus(modes);
  //   $(".aabb").addClass(modes);
  //   const type = localStorage.getItem("type");
  //   if (type == "1") {
  //     setLoandata(true);
  //   }
  //   const sessTime = localStorage.getItem("time");
  //   const currentTimestamp = Math.floor(Date.now() / 1000); // Divide by 1000 to get seconds
  //   //console.log(currentTimestamp + "==" + sessTime)
  //   if (currentTimestamp >= sessTime) {
  //     localStorage.setItem("user", "");
  //     localStorage.setItem("token", "");
  //     localStorage.setItem("pass", "");
  //     localStorage.setItem("aid", "");
  //     localStorage.setItem("margin", "");
  //     localStorage.setItem("web", "");
  //     localStorage.setItem("time", "");
  //     window.location.reload(false);
  //     // setTimeout(() => history.push("/"), 250);
  //   }
  // }, []);

  const [isChecked, setChecked] = useState(false);

  const [isSubmenuVisible, setSubmenuVisibility] = useState(false);
  const handleMouseEnter = () => {
    setSubmenuVisibility(true);
  };
  const handleMouseLeave = () => {
    setSubmenuVisibility(false);
  };

  const [isSubmenuVisible1, setSubmenuVisibility1] = useState(false);
  const handleMouseEnter1 = () => {
    setSubmenuVisibility1(true);
  };
  const handleMouseLeave1 = () => {
    setSubmenuVisibility1(false);
  };

  const [isSubmenuVisible2, setSubmenuVisibility2] = useState(false);
  const handleMouseEnter2 = () => {
    setSubmenuVisibility2(true);
  };
  const handleMouseLeave2 = () => {
    setSubmenuVisibility2(false);
  };

  const [isSubmenuVisible3, setSubmenuVisibility3] = useState(false);
  const handleMouseEnter3 = () => {
    setSubmenuVisibility3(true);
  };
  const handleMouseLeave3 = () => {
    setSubmenuVisibility3(false);
  };

  const [isSubmenuVisible4, setSubmenuVisibility4] = useState(false);
  const handleMouseEnter4 = () => {
    setSubmenuVisibility4(true);
  };
  const handleMouseLeave4 = () => {
    setSubmenuVisibility4(false);
  };

  const handleToggle = () => {
    setTimeout(() => history.push("/"), 0);
  };

  const handleDhan = () => {
    setTimeout(() => history.push("/dhan_account"), 0);
  };

  const handleToggleSakhi = () => {
    setTimeout(() => history.push("/sakhi_account"), 0);
  };
  const Mode = (e) => {
    // //console.log(e.target.class + "6666666")
    var abc = document.getElementById("mode").class;
    if (abc == "fa fa-moon-o") {
      localStorage.setItem("mode", "dark-only");

      $("#mode").removeClass("fa fa-moon-o");
      $("#mode").addClass("fa fa-lightbulb-o");
      $(".aabb").addClass("dark-only");
    } else {
      localStorage.setItem("mode", "");
      $("#mode").removeClass("fa fa-lightbulb-o");
      $("#mode").addClass("fa fa-moon-o");
      $(".aabb").removeClass("dark-only");
    }
    // //console.log(abc)
  };

  return (
    <div>
      <div class="page-header container-fluid" id="page-header">
        <div
          class="header-wrapper  m-0 "
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            class="left-header horizontal-wrapper ps-0"
            style={{ margin: "auto", alignItems: "center" }}
          >
            <div
              class="btn-group btn-group-toggle mb-3"
              style={{
                borderRadius: "28px",
                boxShadow: "2px 2px 2px 0 rgba(0, 0, 0,0.5)",
                border: "5px solid #8b0000",
              }}
            >
              <button
                id="light"
                type="button"
                style={{
                  color: "#fff",
                  backgroundColor: "#8b0000",
                  width: "150px",
                }}
                onClick={handleToggle}
                class=" btn pr-4 pl-4 border-0"
              >
                Mandal
              </button>
              <button
                id="light"
                type="button"
                style={{
                  backgroundColor: "#fff",
                  color: "#8b0000",
                  width: "150px",
                  fontSize:"15px"
                }}
                onClick={handleToggleSakhi}
                class="active btn pr-4 pl-4 border-0"
              >
                Sakhi Saheli
              </button>
              <button
                id="dark"
                type="button"
                style={{
                  backgroundColor: "#8b0000",
                  color: "#fff",
                  width: "150px",
                }}
                onClick={handleDhan}
                class=" btn pr-4 pl-4 border-0"
              >
                DhanVarsha
              </button>
            </div>
          </div>
          <div class="nav-right  pull-right right-header ps-0">
            <ul class="nav-menus">
              <li class="profile-nav onhover-dropdown p-0 me-0">
                <div class="d-flex profile-media">
                  <img
                    class="b-r-50"
                    style={{ height: "40px", width: "40px" }}
                    src="assets/images/MLOGO.png"
                    alt="loading"
                  />
                  <div class="flex-grow-1">
                    <span>{sesspass}</span>
                    <p class="mb-0 font-roboto">
                      Admin <i class="middle fa fa-angle-down"></i>
                    </p>
                  </div>
                </div>
                <ul class="profile-dropdown onhover-show-div">
                  <li>
                    <Link to={"/profile"}>
                      <i data-feather="user"></i>
                      <span>Account </span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/password"}>
                      <i data-feather="mail"></i>
                      <span>Change Password</span>
                    </Link>
                  </li>
                  {loandata ? (
                    ""
                  ) : (
                    <li>
                      <Link onClick={Logout}>
                        <i data-feather="log-in"> </i>
                        <span>Log out</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </div>
          <script class="result-template" type="text/x-handlebars-template">
            <div class="ProfileCard u-cf">
              <div class="ProfileCard-avatar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-airplay m-0"
                >
                  <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div class="ProfileCard-details">
                <div class="ProfileCard-realName">name</div>
              </div>
            </div>
          </script>
          <script class="empty-template" type="text/x-handlebars-template">
            <div class="EmptyMessage">
              Your search turned up 0 results. This most likely means the
              backend is down, yikes!
            </div>
          </script>
        </div>
      </div>

      <div class="page-body-wrapper">
        <div class="sidebar-wrapper" id="page-head">
          <div class="logo-wrapper">
            <Link to={"/account"}>
              <img
                class="img-fluid for-light"
                style={{ height: "55px", width: "auto" }}
                src="../assets/images/MGLOGO.png"
                alt="load"
              />
            </Link>
            <div class="back-btn">
              <img src="assets/images/left.png" onClick={Open} alt="left" />
            </div>
            <div class="toggle-sidebar">
              <img src="assets/images/next.png" onClick={Open} alt="right" />
            </div>
          </div>
          <div class="logo-icon-wrapper">
            <Link to={"/"}>
              <img
                class="img-fluid"
                src="../assets/images/mmlogo.png"
                style={{ height: "45px", width: "45px" }}
                onClick={Open}
                alt="load"
              />
            </Link>
          </div>
          <nav class="sidebar-main">
            <div class="left-arrow disabled" id="left-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
            </div>
            <div id="sidebar-menu">
              <ul
                class="sidebar-links"
                id="simple-bar"
                data-simplebar="init"
                style={{ display: "block" }}
              >
                <div class="simplebar-wrapper" style={{ margin: "-40px" }}>
                  <div class="simplebar-height-auto-observer-wrapper">
                    <div class="simplebar-height-auto-observer"></div>
                  </div>
                  <div class="simplebar-mask">
                    <div
                      class="simplebar-offset"
                      style={{ right: "0px", bottom: "0px" }}
                    >
                      <div
                        class="simplebar-content-wrapper"
                        style={{ height: "100%", overflow: "hidden scroll" }}
                      >
                        <div
                          class="simplebar-content"
                          style={{ margin: "10px" }}
                        >
                          <li class="menu-box" style={{ margin: "10px" }}>
                            <ul>
                              {/* <li class="sidebar-list"><Link class="sidebar-link sidebar-title link-nav" to={'/userregistration'}><DollarSign /><span>User Register</span></Link></li> */}

                              <li class="sidebar-list">
                                <Link
                                  class="sidebar-link sidebar-title link-nav "
                                  to={"/sakhi_account"}
                                >
                                  <GitPullRequest />
                                  <span>Dashboard</span>
                                </Link>
                              </li>
                              {/* <li class="sidebar-list"><Link class="sidebar-link sidebar-title link-nav" to={'/emi_count'}><User /><span>Payment Count</span></Link></li> */}
                              <li
                                className="sidebar-list"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                              >
                                <Link
                                  className="sidebar-link sidebar-title link-nav"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  to="/transaction"
                                >
                                  <MoreHorizontal />
                                  <span> sakhi Regular Installments</span>
                                  {isSubmenuVisible ? (
                                    <ChevronDown
                                      style={{ marginLeft: "auto" }}
                                    />
                                  ) : (
                                    <ChevronRight
                                      style={{ marginLeft: "auto" }}
                                    />
                                  )}
                                </Link>
                                {isSubmenuVisible && (
                                  <ul
                                    className="submenu"
                                    style={{ paddingLeft: "20px" }}
                                  >
                                    <li class="sidebar-list">
                                      <Link
                                        class="sidebar-link sidebar-title link-nav"
                                        to={"/sakhi_transaction"}
                                      >
                                        <Book />
                                        <span>Transactions</span>
                                      </Link>
                                    </li>

                                    <li className="sidebar-list">
                                      <Link
                                        className="sidebar-link sidebar-title link-nav"
                                        to="/sakhi_addmoney"
                                      >
                                        <List />
                                        <span>Regular Installment</span>
                                      </Link>
                                    </li>

                                    <li className="sidebar-list">
                                      <Link
                                        className="sidebar-link sidebar-title link-nav"
                                        to="/sakhi_bulk_installment"
                                      >
                                        <Codepen />
                                        <span>Bulk Installments</span>
                                      </Link>
                                    </li>
                                  </ul>
                                )}
                              </li>

                              <li
                                class="sidebar-list"
                                onMouseEnter={handleMouseEnter1}
                                onMouseLeave={handleMouseLeave1}
                              >
                                <Link
                                  class="sidebar-link sidebar-title link-nav"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  to={"/loan"}
                                >
                                  <Book />
                                  <span>Loans </span>
                                  {isSubmenuVisible1 ? (
                                    <ChevronDown
                                      style={{ marginLeft: "auto" }}
                                    />
                                  ) : (
                                    <ChevronRight
                                      style={{ marginLeft: "auto" }}
                                    />
                                  )}
                                </Link>
                                {isSubmenuVisible1 && (
                                  <ul
                                    class="submenu"
                                    style={{ paddingLeft: "20px" }}
                                  >
                                    <li class="sidebar-list">
                                      <Link
                                        class="sidebar-link sidebar-title link-nav"
                                        to={"/sakhi_loan"}
                                      >
                                        <List />
                                        <span>Loan Details</span>
                                      </Link>
                                    </li>
                                    <li class="sidebar-list">
                                      <Link
                                        class="sidebar-link sidebar-title link-nav"
                                        to={"/sakhi_loanemi"}
                                      >
                                        <Clock />
                                        <span>Regular EMI</span>
                                      </Link>
                                    </li>
                                    <li class="sidebar-list">
                                      <Link
                                        class="sidebar-link sidebar-title link-nav"
                                        to={"/sakhi_bulk_emi"}
                                      >
                                        <Codepen />
                                        <span>Bulk EMI</span>
                                      </Link>
                                    </li>

                                    <li class="sidebar-list">
                                      <Link
                                        class="sidebar-link sidebar-title link-nav"
                                        to={"/sakhi_createloan"}
                                      >
                                        <Edit />
                                        <span>Create Loan</span>
                                      </Link>
                                    </li>
                                    {/* <li class="sidebar-list">
                                      <Link
                                        class="sidebar-link sidebar-title link-nav"
                                        to={"/sakhi_interest_manage"}
                                      >
                                        <Layers />
                                        <span>Interest Manage</span>
                                      </Link>
                                    </li> */}

                                    <li class="sidebar-list">
                                      <Link
                                        class="sidebar-link sidebar-title link-nav"
                                        to={"/sakhi_loanrequest"}
                                      >
                                        <Slack />
                                        <span>Loan Request</span>
                                      </Link>
                                    </li>
                                  </ul>
                                )}
                              </li>
                              <li
                                class="sidebar-list"
                                onMouseEnter={handleMouseEnter2}
                                onMouseLeave={handleMouseLeave2}
                              >
                                <Link
                                  class="sidebar-link sidebar-title link-nav"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <User />
                                  <span>Sakhi Users</span>
                                  {isSubmenuVisible2 ? (
                                    <ChevronDown
                                      style={{ marginLeft: "auto" }}
                                    />
                                  ) : (
                                    <ChevronRight
                                      style={{ marginLeft: "auto" }}
                                    />
                                  )}
                                </Link>
                                {isSubmenuVisible2 && (
                                  <ul
                                    class="submenu"
                                    style={{ paddingLeft: "20px" }}
                                  >
                                    <li>
                                      <Link
                                        class="sidebar-link sidebar-title link-nav"
                                        to={"/sakhi_member"}
                                      >
                                        <UserPlus />
                                        <span>Create Member</span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        class="sidebar-link sidebar-title link-nav"
                                        to={"/sakhi_user"}
                                      >
                                        <Users />
                                        <span>Sakhi Saheli User</span>
                                      </Link>
                                    </li>
                                  </ul>
                                )}
                              </li>
                              <li
                                class="sidebar-list"
                                onMouseEnter={handleMouseEnter3}
                                onMouseLeave={handleMouseLeave3}
                              >
                                <Link
                                  class="sidebar-link sidebar-title link-nav"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Edit />
                                  <span>Extras</span>
                                  {isSubmenuVisible3 ? (
                                    <ChevronDown
                                      style={{ marginLeft: "auto" }}
                                    />
                                  ) : (
                                    <ChevronRight
                                      style={{ marginLeft: "auto" }}
                                    />
                                  )}
                                </Link>
                                {isSubmenuVisible3 && (
                                  <ul
                                    class="submenu"
                                    style={{ paddingLeft: "20px" }}
                                  >
                                    <li class="sidebar-list">
                                      <Link
                                        class="sidebar-link sidebar-title link-nav"
                                        to="/sakhi_withdraw"
                                      >
                                        <Package />
                                        <span>Withdraw</span>
                                      </Link>
                                    </li>
                                    {/* <li class="sidebar-list">
            <Link
              class="sidebar-link sidebar-title link-nav"
              to="/extracharges"
            >
              <FileText />
              <span>Extra Charges</span>
            </Link>
          </li>
          <li class="sidebar-list">
            <Link
              class="sidebar-link sidebar-title link-nav"
              to={"/annual_bonus"}
            >
              <Layout />
              <span>Annual Bonus</span>
            </Link>
          </li> */}
                                  </ul>
                                )}
                              </li>

                              {/* <li class="sidebar-list">
      <Link
        class="sidebar-link sidebar-title link-nav"
        to={"/delete_log"}
      >
        <Delete />
        <span>Delete Log</span>
      </Link>
    </li> */}
                              {/* <li class="sidebar-list"><Link class="sidebar-link sidebar-title link-nav" to={'/transaction_report'}><List /><span>Transaction Report</span></Link></li> */}
                              <li class="sidebar-list">
                                <Link
                                  class="sidebar-link sidebar-title link-nav"
                                  to={"/sakhi_report"}
                                >
                                  <List />
                                  <span>Report</span>
                                </Link>
                              </li>
                              <li class="sidebar-list">
                                  <Link
                                    class="sidebar-link sidebar-title link-nav"
                                    to={"/sakhi_master_report"}
                                  >
                                    <FilePlus />
                                    <span>Master Report</span>
                                  </Link>
                                </li>
                            </ul>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="simplebar-placeholder"
                    style={{ width: "auto", height: "2662px" }}
                  ></div>
                </div>
                <div
                  class="simplebar-track simplebar-horizontal"
                  style={{ visibility: "hidden" }}
                >
                  <div
                    class="simplebar-scrollbar"
                    style={{ width: "0px", display: "none" }}
                  ></div>
                </div>
                <div
                  class="simplebar-track simplebar-vertical"
                  style={{ visibility: "visible" }}
                >
                  <div
                    class="simplebar-scrollbar"
                    style={{
                      height: "304px",
                      transform: "translate3d(0px, 0px, 0px)",
                      display: "block",
                    }}
                  ></div>
                </div>
              </ul>
            </div>
            <div class="right-arrow" id="right-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-right"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SakhiHeader;
