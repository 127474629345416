import React from "react";
import Header from "./header";

import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import Select from 'react-select';
import $ from 'jquery'
import { BASE_URL } from "./config";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


const Member = () => {


    const [selectedOption, setSelectedOption] = useState("");

    const [options, setOptions] = useState([]);
    const [phone, setPhone] = useState("")
    const [check, setCheck] = useState(false);

    const [selectedDate, setSelectedDate] = useState('');

    const handleDateChange = (options) => {
        setSelectedDate(options.value);
        var id = options.value.split(")  ")
        //console.log(id[1])
        setSelectedOption(id[0])
        var am = id[1].split("-");
        setTableData(id[1])
        setDatas(am[1])
        setPhone(am[1])

    };


    const [open, setOpen] = React.useState(false);
    const [datas, setDatas] = useState("")

    const handleClose = () => {
        setOpen(false);
    };

    const initialState = {
        member: "",
        names: "",
        card: "",
        mobile: "",
        date: "",
        month: "",
        interest: "",
        modes: ""
    }

    const [state, setState] = useState(initialState)
    const { member, names, card, mobile, date, month, interest, modes } = state;

    const [tableData, setTableData] = useState([]);

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };




    useEffect(() => {
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                page: "CreateMember"

            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios.post(BASE_URL + 'admin_select_user.php', deleteData).then(data => {
            const optionsData = data.data.result.map((item) => ({ value: item.id + ")  " + (item.name) + "-" + item.phone, label: item.name + "  (" + (item.phone) + ")" }));
            setOptions(optionsData);
        })
            .catch(error => console.error(error));
        Web();
    }, []);

    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }



    let name, value
    const handler = (e) => {
        name = e.target.name;
        value = e.target.value;
        setState({ ...state, [name]: value });

    };

    var a_name = localStorage.getItem('user')
    var aid = localStorage.getItem('aid')
    var pass = localStorage.getItem('pass')
    // //console.log(tableData)





    const Check = (e) => {

        e.preventDefault();
        $('.btn').addClass('disabled');
        if (selectedOption == "") {
            toast.error('Please Select User', { position: toast.POSITION.TOP_RIGHT });
            $('.btn').removeClass('disabled');

        } else {
            const token = localStorage.getItem('token');
            const dObject = {
                authorization: token, input: {

                    uid: selectedOption,
                    name: member,
                    a_name: a_name,
                    c_name: names,
                    aid: aid,
                    auth: pass,
                    c_card: card,
                    c_mobile: mobile,
                    phone: datas,
                    date: date,
                    month: month,
                    latejoin: check,
                    interest: interest,
                    mode: modes
                }
            };
            const encodedData = btoa(JSON.stringify(dObject));
            const deleteData = { "data": encodedData }
            axios
                .post(BASE_URL + "admin_create_member.php", deleteData)
                .then(function (data) {
                    // setOpen(true);
                    console.log(data)
                    if (data.data.message === "radha") {
                        toast.success('Member Created.', { position: toast.POSITION.TOP_RIGHT });
                        setTableData("")
                        alert('New Member Id : ' + data.data.id)
                        setState({ member: "", card: "", mobile: "", names: "", month: "", interest: "", date: "", modes: "" })
                        setCheck(false)
                        $('.btn').removeClass('disabled');

                    } else {
                        toast.error('Member Not Created.', { position: toast.POSITION.TOP_RIGHT });

                    }

                })
        }
    };


    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="page-body" style={{ marginLeft: "320px" }}>
                    <div class="container-fluid">
                        <div class="page-title">
                            <ToastContainer />
                            <div class="row">
                                <div class="col-sm-6">
                                    <h3>Create Member</h3>

                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to={'/'}><i data-feather="home"></i>Dashboard</Link></li>
                                        <li class="breadcrumb-item">Create Member</li>
                                        {/* <li class="breadcrumb-item active">Create Member</li> */}
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid" >
                        <div class="select2-drpdwn">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card" style={{ height: check ? "720px" : "400px" }}>
                                        <div class="card-header pb-0">
                                            <h5 class="card-title">Create Member</h5>
                                        </div>

                                        <div class="card-body o-hidden">
                                            <form onSubmit={Check}>

                                                {/* <input class="form-control" type="text" onChange={(e) => setPname(e.target.value)} onBlur={search} /> */}
                                                <div class="mb-2">
                                                    <div class="col-form-label">Select Name</div>
                                                    <Select
                                                        value={tableData}
                                                        onChange={handleDateChange}
                                                        options={options}
                                                        isSearchable={true}
                                                        defaultValue={tableData}

                                                    />
                                                </div>
                                                <p>{tableData}</p>

                                                <div class="mb-2">
                                                    <label for="name">Name</label>
                                                    <input class="form-control" id="name" type="text" name="member" value={state.member} onChange={handler} required="required" onKeyDownCapture={handleKeyPress} />
                                                </div>
                                                <div class="mb-2">
                                                    <div class="col-form-label">Late Join</div>

                                                    <label ><input type="checkbox" name="latejoin" onChange={() => setCheck(!check)}  /> &nbsp;Late Join</label>

                                                </div>
                                                {check && <>
                                                    <div class="mb-2">
                                                        <label for="name">Number of Months</label>
                                                        <input class="form-control" id="name" type="number" value={state.month} name="month" onChange={handler} onKeyDownCapture={handleKeyPress} />
                                                    </div>
                                                    <div class="mb-2">
                                                        <label for="name">Interest</label>
                                                        <input class="form-control" id="name" type="number" value={state.interest} name="interest" onChange={handler} onKeyDownCapture={handleKeyPress} />
                                                    </div>
                                                    <div class="mb-2">
                                                        <label for="name">Joining Date</label>
                                                        <input class="form-control" id="name" type="date" value={state.date} name="date" onChange={handler} onKeyDownCapture={handleKeyPress} />
                                                    </div>
                                                    <div class="mb-2">
                                                        <div class="name">Payment Mode</div>
                                                        <select class="form-select col-sm-12" onChange={handler} value={state.modes} name="modes" required="required" onKeyDownCapture={handleKeyPress}>
                                                            <option value="">Select Payment Mode</option>
                                                            <option value="UPI">UPI</option>
                                                            <option value="Cash">Cash</option>
                                                            <option value="Bank Transfer">Bank Transfer</option>
                                                        </select>
                                                    </div>
                                                </>}
                                                {/* <div class="mb-2">
                                                    <label for="name">Phone No.</label>
                                                    <input class="form-control" id="phone" type="text" name="phone" value={state.phone} onChange={handler} required="required" onKeyDownCapture={handleKeyPress} />
                                                </div> */}
                                                {/* <div class="mb-2">
                                                    <label for="name">C/O Name</label>
                                                    <input class="form-control" id="name" type="text" value={state.names} name="names" onChange={handler} onKeyDownCapture={handleKeyPress} />
                                                </div>
                                                <div class="mb-2">
                                                    <label for="name">C/O Card</label>
                                                    <input class="form-control" id="name" type="text" value={state.card} name="card" onChange={handler} onKeyDownCapture={handleKeyPress} />
                                                </div>
                                                <div class="mb-2">
                                                    <label for="name">C/O Mobile</label>
                                                    <input class="form-control" id="name" type="number" value={state.mobile} name="mobile" onChange={handler} onKeyDownCapture={handleKeyPress} />
                                                </div> */}


                                                <div class="mb-2">
                                                    <button class="btn btn-primary" onKeyDown={handleKeyPress} type="submit">Create Member</button>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}


export default Member