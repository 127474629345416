import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DataTable, { createTheme } from 'react-data-table-component';
import { BASE_URL } from "./config";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "./loader";


const Dhan_transaction = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [selectedId, setSelectedId] = useState("");
    const [tableData, setTableData] = useState([]);
    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')

    useEffect(() => {
        Web();
        abc();
    }, []);

    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }



    const abc = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": sesspass, "page": "transaction" } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "dhan_admin_account.php", deleteData)
            .then(function (data) {
                if (data.data.message == "radha") {
                    setTableData(data.data.trans_result);
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                }
            })

            .catch(error => console.error(error))
    }


    const deletes = () => {

        const selectedRow = filteredData.find(row => row.id === selectedId);
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": sesspass, "page": "transaction", "id": selectedRow.id } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "dhan_del_user.php", deleteData)
            // .then(response => response.json())
            .then(function (data) {
                if (data.data.message === "radha") {
                    toast.success('Data Deleted Successfully', { position: toast.POSITION.TOP_RIGHT });
                    abc();
                } else {
                    toast.error('Data Not Delete', { position: toast.POSITION.TOP_RIGHT });

                }
            }
            )

            .catch(error => console.error(error))

    }

    const coolor = "Withdraw"
    const coolor1 = "INWITHDRAW"
    const columns = [

        { name: 'Date', selector: row => row['date'], sortable: true, filterable: true },
        { name: 'Card No', selector: row => row['did'], sortable: true, filterable: true },
        { name: 'Name', selector: row => row['name'], sortable: true, filterable: true },
        {
            name: 'Type',
            selector: row => row['t_type'],
            sortable: true,
            filterable: true,
            cell: (row) => (
                <div
                    className="badge"
                    style={{
                        backgroundColor:
                            row.t_type === coolor
                                ? "#ea0b3596"
                                : row.t_type === "Regular"
                                    ? "#c3e0b8"
                                    : "gray", // You may want to set a default color or remove this line if not needed
                    }}
                >
                    {row['t_type']}
                </div>
            ),
            // cell: row => (
            //     <div style={{ color: row['t_type'] === coolor || row['t_type'] === coolor1 ? 'red' : 'green' }}>{row.t_type === coolor1 ? 'WITHDRAW' : row.t_type}</div>
            // ),
        },
        { name: 'Installment', selector: row => "₹ " +row['amount'], sortable: true, filterable: true },
        { name: 'Bonus', selector: row => "₹ " +row['bonus'], sortable: true, filterable: true },
        { name: 'Payment Mode', selector: row => row['payment_mode'], sortable: true, filterable: true },
        { name: 'GST', selector: row =>"₹ " + row['gst'], sortable: true },
        { name: 'By', selector: row => row['by'], sortable: true, filterable: true },
        { name: 'Total', selector: row => "₹ " +row['total'], sortable: true, filterable: true, style: { fontWeight: 'bold' } },
        {

            name: 'Delete', button: true, cell: (row) => (
                (row.emi_count == row.emis || row.t_type == "Withdraw") &&
                <img src="assets/images/bin.png" data-bs-toggle="modal" data-original-title="test" onClick={() => setSelectedId(row.id)} data-bs-target="#exampleModal" height={20} width={20} />
            ),
        },

    ];
    const [searchText, setSearchText] = useState('');

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };
    const filteredData = tableData.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        // item.emi_count.toString().includes(searchText) ||
        item.t_type.toLowerCase().includes(searchText.toLowerCase()) ||
        // item.bonus.toString().includes(searchText) ||
        // item.gst.toString().includes(searchText) ||
        item.did.toString().includes(searchText)
        // item.total.toString().includes(searchText) ||
        // item.payment_mode.toLowerCase().includes(searchText.toLowerCase())
    );


    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">

                            <div class="modal-body">Are You Sure ?</div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" onClick={() => deletes()} data-bs-dismiss="modal">Yes</button>
                                <button class="btn btn-dark" type="button" data-bs-dismiss="modal">No</button>

                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />

                <div class="page-body" style={{ marginLeft: "320px" }}>
                    <div class="container-fluid">
                        <div class="col-sm-12">
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "20px" }}>
                                <Link class="btn btn-primary" style={{ textAlign: "center", marginRight: "10px" }} to={"/dhan_add_money"}>
                                    Regular Installment
                                </Link>
                                <Link class="btn btn-primary" style={{ textAlign: "center" }} to={"/dhan_user_bulk_emi"}>
                                    Bulk Installment
                                </Link>
                            </div>
                            <div class="card" style={{ minHeight: "250px" }}>
                                <div class="card-header pb-0">
                                    <h5>Dhanvarsha Transactions</h5><span></span>
                                </div>
                                {isLoading ? (
                                    <Loader />
                                ) : (<>
                                    <div class="card-body">
                                        <div class="table-responsive">

                                            <DataTable
                                                columns={columns}
                                                data={filteredData}
                                                pagination
                                                subHeader
                                                subHeaderComponent={
                                                    <input type="text" placeholder="Search" class="form-control" style={{ width: "200px" }} value={searchText} onChange={handleSearch} />
                                                }

                                            />
                                        </div>
                                    </div>
                                </>)}
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default Dhan_transaction;