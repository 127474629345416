import React from "react";
import Header from "./header";

import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import Select from 'react-select';
import DataTable, { createTheme } from 'react-data-table-component';
import $, { event } from 'jquery'
import { BASE_URL } from "./config";


const SakhiEMIcount = () => {

    const [selectedOption, setSelectedOption] = useState("");

    const [options, setOptions] = useState([]);

    const [selectedDate, setSelectedDate] = useState('');
    const [tableData, setTableData] = useState([]);
    const [loanData, setLoanData] = useState([]);
    const [data, setData] = useState([]);


    const handleDateChange = (options) => {
        setSelectedDate(options.value);
        //console.log(options.value);
        var id = options.value.split("-")
        setSelectedOption(id[0])
        //console.log(id[1])

        if (tableData) {
            setTableData([]);
            setData([]);
            setLoanData([]);
        }
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: {  mobile: id[1] } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        //console.log(deleteData)
        axios
            .post(BASE_URL + "sakhi_emi_count.php",deleteData)
            .then(function (data) {
                //console.log(data)
                if (data.data.message == "valid") {
                    setData(data.data.result[0]);
                    //console.log(data.data.result[0].total)
                }
                if (data.data.tmsg == "radha") {
                    setTableData(data.data.trans_result);
                }
                if (data.data.lmsg == "radha") {
                    setLoanData(data.data.loan_result);
                }
            })

            .catch(error => console.error(error))
    };




    useEffect(() => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: {  page: "SakhiCreateMember" } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios.post(BASE_URL + 'admin_select_user.php', deleteData).then(data => {
            //console.log(data)
            const optionsData = data.data.result.map((item) => ({ value: item.id + ")  " + (item.name) + "-" + item.phone, label: item.name + "  (" + (item.phone) + ")" }));
            setOptions(optionsData);
        })
            .catch(error => console.error(error));
        Web();
    }, []);

   var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')

    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }







    const Check = (event) => {

        event.preventDefault();


    };



    const margin = localStorage.getItem("margin")

    return (
        <div>
            {/* <div class="page-body-wrapper" style={{marginTop:"0px",minHeight:"0px"}}>
                <div class="page-body" style={{ marginLeft: "320px" }}> */}
                    {/* <div class="container-fluid">
                        <div class="page-title">
                            <div class="row">
                                <ToastContainer />

                                <div class="col-sm-6">
                                    <h3>Payment Count</h3>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to={'/'}><i data-feather="home"></i></Link>Dashboard</li>
                                        <li class="breadcrumb-item">Payment Count</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div class="container-fluid">
                        <div class="select2-drpdwn">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-header pb-0">
                                            <h5 class="card-title">Payment Count</h5>
                                        </div>
                                        <div class="card-body o-hidden">
                                            <form onSubmit={Check}>

                                                <div class="mb-2">
                                                    <div class="col-form-label">Select User</div>
                                                    <Select
                                                        value={selectedDate}
                                                        onChange={handleDateChange}
                                                        options={options}
                                                        isSearchable={true}
                                                        title="Transactions"

                                                    />
                                                    <p>{selectedDate}</p>
                                                </div>

                                                <div class=" general-widget">

                                                    <div class="row">

                                                        <div class="col-sm-4 col-lg-4">
                                                            <div class="card o-hidden">
                                                                <div class="card-body">
                                                                    <div class="d-flex static-widget" style={{ color: "#8b0000" }}>
                                                                        <div class="flex-grow-1">
                                                                            <h6 class="font-roboto" >Total Payable</h6>
                                                                            <div >
                                                                            <h4 class="mb-0 counter" > ₹ {data.total} </h4><h6 style={{color:"black"}}>{data.type}</h6>
                                                                            </div>
                                                                        </div>
                                                                        <svg class="fill-success" style={{ fill: '#8b0000' }} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M22.5938 14.1562V17.2278C20.9604 17.8102 19.7812 19.3566 19.7812 21.1875C19.7812 23.5138 21.6737 25.4062 24 25.4062C24.7759 25.4062 25.4062 26.0366 25.4062 26.8125C25.4062 27.5884 24.7759 28.2188 24 28.2188C23.2241 28.2188 22.5938 27.5884 22.5938 26.8125H19.7812C19.7812 28.6434 20.9604 30.1898 22.5938 30.7722V33.8438H25.4062V30.7722C27.0396 30.1898 28.2188 28.6434 28.2188 26.8125C28.2188 24.4862 26.3263 22.5938 24 22.5938C23.2241 22.5938 22.5938 21.9634 22.5938 21.1875C22.5938 20.4116 23.2241 19.7812 24 19.7812C24.7759 19.7812 25.4062 20.4116 25.4062 21.1875H28.2188C28.2188 19.3566 27.0396 17.8102 25.4062 17.2278V14.1562H22.5938Z"></path>
                                                                            <path d="M25.4062 0V11.4859C31.2498 12.1433 35.8642 16.7579 36.5232 22.5938H48C47.2954 10.5189 37.4829 0.704531 25.4062 0Z"></path>
                                                                            <path d="M14.1556 31.8558C12.4237 29.6903 11.3438 26.9823 11.3438 24C11.3438 17.5025 16.283 12.1958 22.5938 11.4859V0C10.0492 0.731813 0 11.2718 0 24C0 30.0952 2.39381 35.6398 6.14897 39.8624L14.1556 31.8558Z"></path>
                                                                            <path d="M47.9977 25.4062H36.5143C35.8044 31.717 30.4977 36.6562 24.0002 36.6562C21.0179 36.6562 18.3099 35.5763 16.1444 33.8444L8.13779 41.851C12.3604 45.6062 17.905 48 24.0002 48C36.7284 48 47.2659 37.9508 47.9977 25.4062Z"></path>
                                                                        </svg>
                                                                    </div>
                                                                    {/* <div class="progress-widget">
                                                                        <div class="progress sm-progress-bar progress-animate">
                                                                            <div class="progress-gradient-danger" role="progressbar" style={{ width: '75%', ariaValuenow: '75', ariaValuemin: '00', ariaValuemax: '100' }}><span class="animate-circle"></span></div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 col-lg-4">
                                                            <div class="card o-hidden">
                                                                <div class="card-body">
                                                                    <div class="d-flex static-widget" style={{ color: "#8b0000" }}>
                                                                        <div class="flex-grow-1">
                                                                            <h6 class="font-roboto">Regular Installment</h6>
                                                                            <h4 class="mb-0 counter"> ₹ {data.reg_installment}</h4>
                                                                        </div>
                                                                        <svg class="fill-danger" style={{ fill: '#8b0000' }} width="45" height="39" viewBox="0 0 45 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5.92047 8.49509C5.81037 8.42629 5.81748 8.25971 5.93378 8.20177C7.49907 7.41686 9.01464 6.65821 10.5302 5.89775C14.4012 3.95495 18.2696 2.00762 22.1478 0.0792996C22.3387 -0.0157583 22.6468 -0.029338 22.8359 0.060288C28.2402 2.64315 33.6357 5.24502 39.033 7.84327C39.0339 7.84327 39.0339 7.84417 39.0348 7.84417C39.152 7.90121 39.1582 8.06869 39.0472 8.1375C38.9939 8.17009 38.9433 8.20087 38.8918 8.22984C33.5398 11.2228 28.187 14.2121 22.8385 17.2115C22.5793 17.3572 22.3839 17.3762 22.1131 17.2296C16.7851 14.3507 11.4518 11.4826 6.12023 8.61188C6.05453 8.57748 5.98972 8.53855 5.92047 8.49509Z"></path>
                                                                            <path d="M21.1347 23.3676V38.8321C21.1347 38.958 21.0042 39.0386 20.895 38.9806C20.4182 38.7271 19.9734 38.4918 19.5295 38.2528C14.498 35.5441 9.46833 32.8317 4.43154 30.1339C4.12612 29.97 4.02046 29.7944 4.02224 29.4422C4.03822 26.8322 4.03023 24.2222 4.02934 21.6122C4.02934 21.4719 4.02934 21.3325 4.02934 21.1659C4.02934 21.0428 4.15542 20.9622 4.26373 21.0147C4.35252 21.0581 4.43065 21.0962 4.50434 21.1396C8.18539 23.2888 11.8664 25.438 15.5457 27.5909C16.5081 28.154 17.0622 28.0453 17.7627 27.1464C18.7748 25.8472 19.7896 24.5508 20.8045 23.2535C20.8053 23.2526 20.8062 23.2517 20.8071 23.2499C20.9172 23.1132 21.1347 23.192 21.1347 23.3676Z"></path>
                                                                            <path d="M23.83 23.3784C23.83 23.2019 24.0484 23.1241 24.1567 23.2626C25.2168 24.6178 26.2192 25.9016 27.2233 27.1835C27.8928 28.039 28.4504 28.1494 29.3719 27.6117C33.0521 25.4643 36.7323 23.316 40.4133 21.1686C40.4914 21.1233 40.5713 21.0799 40.6592 21.0337C40.7613 20.9803 40.8856 21.0473 40.8972 21.164C40.9025 21.2184 40.9069 21.2691 40.9069 21.3189C40.9087 23.928 40.9052 26.5371 40.9132 29.1462C40.914 29.4006 40.8421 29.5518 40.6131 29.6794C35.1057 32.7539 29.6037 35.8365 24.099 38.9163C24.0892 38.9218 24.0803 38.9263 24.0706 38.9317C23.9605 38.9879 23.8309 38.9082 23.8309 38.7833L23.83 23.3784Z"></path>
                                                                            <path d="M28.4752 24.454C27.2908 22.9385 26.118 21.4384 24.9203 19.9066C24.6983 19.6232 24.7809 19.2031 25.0925 19.0293L41.3092 9.95809C41.5746 9.80962 41.9076 9.89743 42.0692 10.1582C43.0147 11.6791 43.9541 13.1891 44.9103 14.7264C45.0852 15.0079 44.9946 15.3818 44.7114 15.5475C39.5414 18.5649 34.3875 21.5742 29.2086 24.5979C28.9627 24.74 28.651 24.6794 28.4752 24.454Z"></path>
                                                                            <path d="M20.0132 19.931C18.819 21.4592 17.6506 22.9539 16.4804 24.4512C16.3037 24.6767 15.9921 24.7373 15.747 24.5943C10.586 21.5814 5.45504 18.5857 0.288619 15.5701C6.65486e-05 15.4017 -0.087831 15.0188 0.0968427 14.7372C1.02554 13.3204 1.94269 11.9208 2.86872 10.5085C3.03209 10.2596 3.35349 10.1763 3.61363 10.3157C9.018 13.2254 14.3975 16.1215 19.833 19.0483C20.1508 19.2194 20.2378 19.644 20.0132 19.931Z"></path>
                                                                        </svg>
                                                                    </div>
                                                                    {/* <div class="progress-widget">
                                                                        <div class="progress sm-progress-bar progress-animate">
                                                                            <div class="progress-gradient-danger" role="progressbar" style={{ width: '75%', ariaValuenow: '75', ariaValuemin: '00', ariaValuemax: '100' }}><span class="animate-circle"></span></div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 col-lg-4">
                                                            <div class="card o-hidden">
                                                                <div class="card-body">
                                                                    <div class="d-flex static-widget" style={{ color: "#8b0000" }}>
                                                                        <div class="flex-grow-1">
                                                                            <h6 class="font-roboto">Loan Installment</h6>
                                                                            <h4 class="mb-0 counter"> ₹  {data.emi}</h4>
                                                                        </div>
                                                                        <svg class="fill-danger" style={{ fill: '#8b0000' }} width="45" height="39" viewBox="0 0 45 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5.92047 8.49509C5.81037 8.42629 5.81748 8.25971 5.93378 8.20177C7.49907 7.41686 9.01464 6.65821 10.5302 5.89775C14.4012 3.95495 18.2696 2.00762 22.1478 0.0792996C22.3387 -0.0157583 22.6468 -0.029338 22.8359 0.060288C28.2402 2.64315 33.6357 5.24502 39.033 7.84327C39.0339 7.84327 39.0339 7.84417 39.0348 7.84417C39.152 7.90121 39.1582 8.06869 39.0472 8.1375C38.9939 8.17009 38.9433 8.20087 38.8918 8.22984C33.5398 11.2228 28.187 14.2121 22.8385 17.2115C22.5793 17.3572 22.3839 17.3762 22.1131 17.2296C16.7851 14.3507 11.4518 11.4826 6.12023 8.61188C6.05453 8.57748 5.98972 8.53855 5.92047 8.49509Z"></path>
                                                                            <path d="M21.1347 23.3676V38.8321C21.1347 38.958 21.0042 39.0386 20.895 38.9806C20.4182 38.7271 19.9734 38.4918 19.5295 38.2528C14.498 35.5441 9.46833 32.8317 4.43154 30.1339C4.12612 29.97 4.02046 29.7944 4.02224 29.4422C4.03822 26.8322 4.03023 24.2222 4.02934 21.6122C4.02934 21.4719 4.02934 21.3325 4.02934 21.1659C4.02934 21.0428 4.15542 20.9622 4.26373 21.0147C4.35252 21.0581 4.43065 21.0962 4.50434 21.1396C8.18539 23.2888 11.8664 25.438 15.5457 27.5909C16.5081 28.154 17.0622 28.0453 17.7627 27.1464C18.7748 25.8472 19.7896 24.5508 20.8045 23.2535C20.8053 23.2526 20.8062 23.2517 20.8071 23.2499C20.9172 23.1132 21.1347 23.192 21.1347 23.3676Z"></path>
                                                                            <path d="M23.83 23.3784C23.83 23.2019 24.0484 23.1241 24.1567 23.2626C25.2168 24.6178 26.2192 25.9016 27.2233 27.1835C27.8928 28.039 28.4504 28.1494 29.3719 27.6117C33.0521 25.4643 36.7323 23.316 40.4133 21.1686C40.4914 21.1233 40.5713 21.0799 40.6592 21.0337C40.7613 20.9803 40.8856 21.0473 40.8972 21.164C40.9025 21.2184 40.9069 21.2691 40.9069 21.3189C40.9087 23.928 40.9052 26.5371 40.9132 29.1462C40.914 29.4006 40.8421 29.5518 40.6131 29.6794C35.1057 32.7539 29.6037 35.8365 24.099 38.9163C24.0892 38.9218 24.0803 38.9263 24.0706 38.9317C23.9605 38.9879 23.8309 38.9082 23.8309 38.7833L23.83 23.3784Z"></path>
                                                                            <path d="M28.4752 24.454C27.2908 22.9385 26.118 21.4384 24.9203 19.9066C24.6983 19.6232 24.7809 19.2031 25.0925 19.0293L41.3092 9.95809C41.5746 9.80962 41.9076 9.89743 42.0692 10.1582C43.0147 11.6791 43.9541 13.1891 44.9103 14.7264C45.0852 15.0079 44.9946 15.3818 44.7114 15.5475C39.5414 18.5649 34.3875 21.5742 29.2086 24.5979C28.9627 24.74 28.651 24.6794 28.4752 24.454Z"></path>
                                                                            <path d="M20.0132 19.931C18.819 21.4592 17.6506 22.9539 16.4804 24.4512C16.3037 24.6767 15.9921 24.7373 15.747 24.5943C10.586 21.5814 5.45504 18.5857 0.288619 15.5701C6.65486e-05 15.4017 -0.087831 15.0188 0.0968427 14.7372C1.02554 13.3204 1.94269 11.9208 2.86872 10.5085C3.03209 10.2596 3.35349 10.1763 3.61363 10.3157C9.018 13.2254 14.3975 16.1215 19.833 19.0483C20.1508 19.2194 20.2378 19.644 20.0132 19.931Z"></path>
                                                                        </svg>
                                                                    </div>
                                                                    {/* <div class="progress-widget">
                                                                        <div class="progress sm-progress-bar progress-animate">
                                                                            <div class="progress-gradient-danger" role="progressbar" style={{ width: '75%', ariaValuenow: '75', ariaValuemin: '00', ariaValuemax: '100' }}><span class="animate-circle"></span></div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="mt-4">
                                                    
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                {/* </div>
            </div> */}

        </div>
    )
}
export default SakhiEMIcount