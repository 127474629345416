// Loader.js
import React from 'react';
import Circle from 'react-preloaders';

const Loader = () => {
    return (
        //     <div class="container">
        //     <div class="preloader"></div> 
        //   </div>
        <>
           <div class="preloader1">
			<svg viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path class="big-circle svgs" d="M101 51C101 78.6142 78.6142 101 51 101C23.3858 101 1 78.6142 1 51" stroke="#8b0000" stroke-width="3"/>
				<path class="small-circle svgs" d="M91 51C91 28.9086 73.0914 11 51 11C28.9086 11 11 28.9086 11 51" stroke="#8b0000" stroke-width="3"/>
                {/* <path class="exsmall-circle svgs" d="M50 20C50 8.9543 41.0457 0 30 0C18.9543 0 10 8.9543 10 20" stroke="#8b0000" stroke-width="3" fill="transparent"/> */}
			</svg>
		</div>
        </>
    );
};

export default Loader;
