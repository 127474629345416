import React, { useEffect } from "react";
import Header from "./header";

import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { BASE_URL } from "./config";

const Employe = () => {



    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    const web = localStorage.getItem("web")


    const initialState = {
        email: "",
        passwordss: "",
        usernames: "",
        mobile: ""
    }


    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    const [state, setState] = useState(initialState)
    const { email, passwordss, usernames, mobile } = state;

    var aaid = localStorage.getItem('aid');
    let name, value
    const handler = (e) => {
        name = e.target.name;
        value = e.target.value;
        setState({ ...state, [name]: value });
    };

    //console.log("tets")
    useEffect(() => {
        Web();
    }, [state]);


    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": web } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    window.location.reload(false);
                }
            })
    }
    const Check = (e) => {
        e.preventDefault();
        $('.btn').addClass('disabled')
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                mobile: mobile,
                password: passwordss,
                name: usernames,
                aid: aaid,
                email: email,

            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "admin_employee_registration.php", deleteData)
            .then(function (data) {

                //console.log(data.data.message);
                if (data.data.message == "radha") {
                    toast.success('Employee Added Successfully', { position: toast.POSITION.TOP_RIGHT });
                    $('.btn').removeClass('disabled')
                    setState({ usernames: "", email: "", mobile: "", passwordss: "" })

                }else if(data.data.message === "mobile"){
                    toast.error('Mobile Number Already Registered', { position: toast.POSITION.TOP_RIGHT });
                    $('.btn').removeClass('disabled')
                }else if(data.data.message === "email"){
                    toast.error('Email Id Already Registered', { position: toast.POSITION.TOP_RIGHT });
                    $('.btn').removeClass('disabled')
                }

            })

    };

    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <ToastContainer />
                <div class="page-body" style={{ marginLeft: "320px" }}>

                    <div class="container-fluid">
                        <div class="page-title">
                            <div class="row">
                                <div class="col-sm-6">
                                    <h3>Add Employee</h3>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to={'/'}><i data-feather="home"></i>Dashboard</Link></li>
                                        <li class="breadcrumb-item">Add Employee</li>
                                        {/* <li class="breadcrumb-item active"> Add Employee</li> */}
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Container-fluid starts--> */}
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card">
                                    {/* <div class="card-header pb-0">
                                        <h5>Add Employee</h5><span></span>
                                    </div> */}
                                    <div class="card-body">
                                        <form onSubmit={Check}>
                                            <div class="tab">
                                                <div class="mb-3">
                                                    <label for="name">Name</label>
                                                    <input class="form-control" type="text" name="usernames" value={state.usernames} onChange={handler} placeholder="" required="required" onKeyDownCapture={handleKeyPress} />
                                                </div>
                                                <div class="mb-3">
                                                    <label for="lname">Email</label>
                                                    <input class="form-control" type="text" name="email" onChange={handler} value={state.email} placeholder="" autocomplete="off" required="required" onKeyDownCapture={handleKeyPress} />
                                                    {/* <p style={{ color: 'red' }}>{errors}</p> */}
                                                </div>
                                                <div class="mb-3">
                                                    <label for="contact">Contact No.</label>
                                                    <input class="form-control" type="number" name="mobile" onChange={handler} value={state.mobile} placeholder="" autocomplete="off" required="required" onKeyDownCapture={handleKeyPress} />
                                                </div>
                                                <div class="mb-3">
                                                    <label for="contact">Password</label>
                                                    <input class="form-control" type="password" name="passwordss" onChange={handler} value={state.passwordss} placeholder="" autocomplete="off" required="required" onKeyDownCapture={handleKeyPress} />
                                                </div>
                                                <div class="mb-3">
                                                    <button class="btn btn-primary" onKeyDown={handleKeyPress} type="submit">ADD EMPLOYEE</button>

                                                </div>

                                            </div>


                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}


export default Employe;