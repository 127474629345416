import React from "react";
import Header from "./header";

import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import $, { event } from "jquery";
import { BASE_URL } from "./config";

const Bulk_emi = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [visible, setVisible] = useState(false);
  const [loanid, setLoanid] = useState("");
  const [selected, setSelected] = useState("");
  const [amount, setAmount] = useState("");
  const [bulkmonth, setBulkmonth] = useState("");
  const [rmonth, setRmonth] = useState("");

  var a_name = localStorage.getItem("user");
  var aid = localStorage.getItem("aid");
  var pass = localStorage.getItem("pass");
  const initialState = {
    member: "",
    names: "",
    card: "",
    mobile: "",
    modes: "",
    date: "",
  };
  const [selectedDate, setSelectedDate] = useState("");
  const [state, setState] = useState(initialState);
  const { member, names, card, mobile, modes, date } = state;
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [options, setOptions] = useState([]);
  const [emivalue, setEmivalue] = useState("");
  const [interest, setInterest] = useState("");
  const [lamount, setLamount] = useState("");
  const [lint, setLint] = useState("");
  const [curmonth, setCurmonth] = useState("");
  const [month, setMonth] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    init();
    Web();
  }, []);

  const web = localStorage.getItem("web");

  const Web = () => {
    const token = localStorage.getItem("token");
    const dObject = { authorization: token, input: { aid: aid, auth: web } };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(BASE_URL + "web_auth.php", deleteData)
      .then(function (data) {
        const abc = data.data.web;
        if (abc !== web) {
          localStorage.setItem("user", "");
          localStorage.setItem("token", "");
          localStorage.setItem("pass", "");
          localStorage.setItem("aid", "");
          localStorage.setItem("margin", "");
          localStorage.setItem("web", "");
          toast.error("You can not use on multiple device at a same time", {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload(false);
        }
        //console.log(data)
      })
      .catch((error) => console.error(error));
  };

  const init = () => {
    const token = localStorage.getItem("token");
    const dObject = { authorization: token, input: { page: "AddEmi" } };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(BASE_URL + "loanemi_user.php", deleteData)
      // .then(response => response.json())
      .then((data) => {
        const optionsData = data.data.result.map((item) => ({
          value:
            item.id +
            "-" +
            item.loan_type +
            "-" +
            item.emi +
            "-" +
            item.name +
            "-" +
            item.remaining +
            "-" +
            item.lid +
            "-" +
            item.amount +
            "-" +
            item.remain_month +
            "-" +
            item.interest +
            "-" +
            item.month,
          label:
            item.id +
            ")  " +
            item.name +
            "   [" +
            item.remaining +
            "/" +
            item.amount +
            "]",
        }));
        setOptions(optionsData);
      })
      .catch((error) => console.error(error));
  };

  const Loans = (option) => {
    setSelectedOption(option.value);

    const aa = option.value.split("-");
    setTableData(aa[2]);
    setLoanid(aa[5]);
    setLamount(aa[6]);
    setRmonth(aa[7]);
    setLint(aa[8]);
    setMonth(aa[9]);
    setEmivalue(aa[1]);
    setSelectedDate(aa[0] + ")  " + aa[3]);
    setAmount(aa[4]);
    var c_mon = month - rmonth;
    setCurmonth(aa[9] - aa[7]);
    setVisible("true");
  };

  const Bulk = (e) => {
    var mmm = e.target.value;
    if (mmm <= curmonth ) {
      setSelected(mmm);
      var bmonth = mmm * tableData;
      setBulkmonth(bmonth);
      var m_int = tableData / month;
      var r_int = (mmm - 1) * m_int;
      setData(r_int);
    } else {
      toast.error("Enter Valid Months", { position: toast.POSITION.TOP_RIGHT });
    }
  };

  let name, value;
  const handler = (e) => {
    name = e.target.name;
    value = e.target.value;
    setState({ ...state, [name]: value });
  };

  const interestcal = (e) => {
    const i = e.target.value;
    setInterest(i);
    const mul = i * amount;
    //console.log(mul)
    const iamount = Math.round(mul / 100);
    //console.log(iamount)
    setSelectedValue(iamount);
    const totalamount = parseInt(bulkmonth) + parseInt(iamount);
    setTotal(totalamount);
  };

  const token = localStorage.getItem("token");
  //console.log(token)
  const Check = (e) => {
    $(".btn").addClass("disabled");

    let f_total = "";

    e.preventDefault();
    if (emivalue === "OLD") {
      f_total = total;
    } else {
      f_total = tableData;
    }

    const token = localStorage.getItem("token");
    const dObject = {
      authorization: token,
      input: {
        mid: selectedOption,
        admin_name: a_name,
        trans_type: "EMI",
        aid: aid,
        auth: pass,
        emi: tableData,
        emi_interest: selectedValue,
        bulk_amount: bulkmonth,
        bulk_month: selected,
        r_int: data,
        loan_id: loanid,
        emi_type: emivalue,
        total: f_total,
        payment_mode: modes,
        percent: interest,
        date: date,
      },
    };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(BASE_URL + "admin_bulk_emi.php", deleteData)
      .then(function (data) {
        //console.log(data)
        if (data.data.message == "radha") {
          setTableData("");
          setTotal("");
          setSelectedValue("");
          setSelectedDate("");
          setState({ modes: "", date: "" });
          setInterest("");
          setBulkmonth("");
          setLamount("");
          setAmount("");
          setCurmonth("");
          setInterest("");
          setSelected("");
          setData("");
          setLint("");
          toast.success("Transaction successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          $(".btn").removeClass("disabled");
          init();
        } else {
          toast.error("Transaction Not Done", {
            position: toast.POSITION.TOP_RIGHT,
          });
          $(".btn").removeClass("disabled");
        }
      })
      .catch((error) => console.error(error));
  };

  const margin = localStorage.getItem("margin");

  return (
    <div>
      <div class="page-body-wrapper">
        <div class="page-body" style={{ marginLeft: "320px" }}>
          <div class="container-fluid">
            <div class="page-title">
              <ToastContainer />
              <div class="row">
                <div class="col-sm-6">
                  <h3>Bulk Loan EMI</h3>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to={"/"}>
                        <i data-feather="home"></i>Dashboard
                      </Link>
                    </li>
                    <li class="breadcrumb-item">Bulk Loan EMI</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid" style={{ paddingBottom: "300px" }}>
            <div class="select2-drpdwn">
              <div class="row">
                <div class="col-md-12" style={{ height: "300px" }}>
                  <div class="card">
                    <div class="card-header pb-0">
                      <h5 class="card-title">Bulk Loan EMI</h5>
                    </div>

                    <div class="card-body o-hidden">
                      <form onSubmit={Check}>
                        <div class="mb-2">
                          <div class="col-form-label">Loan EMI</div>

                          <Select
                            value={selectedOption}
                            onChange={Loans}
                            options={options}
                            isSearchable={true}
                          />
                        </div>
                        <div class="mb-2">
                          <h5>{selectedDate}</h5>
                        </div>
                        <div class="row">
                          <div class="card col-md-3">
                            <p>Loan Amount</p>
                            <b>{lamount}</b>
                          </div>
                          <div class="card col-md-3">
                            <p>Remain EMI</p>
                            <b>{amount}</b>
                          </div>
                          <div class="card col-md-3">
                            <p>Remain Month</p>
                            <b>{curmonth}</b>
                          </div>
                          <div class="card p-2 col-md-3">
                            <p>Interest</p>
                            <b>{lint}</b>
                          </div>
                        </div>
                        <>
                          <div class="mb-2">
                            <label for="name">Installment</label>
                            <input
                              class="form-control"
                              id="name"
                              type="text"
                              value={tableData}
                              name="amount"
                              onChange={handler}
                              required="required"
                              onKeyDownCapture={handleKeyPress}
                            />
                          </div>
                          <div class="mb-2">
                            <label for="name">Bulk Month</label>
                            <input
                              class="form-control"
                              id="name"
                              type="text"
                              name="amount"
                              onChange={(e) => Bulk(e)}
                              required="required"
                              value={selected}
                              onKeyDownCapture={handleKeyPress}
                            />
                          </div>
                          <div class="mb-2">
                            <label for="name">Bulk Installment</label>
                            <input
                              class="form-control"
                              id="name"
                              type="text"
                              value={bulkmonth}
                              name="amount"
                              onChange={handler}
                              required="required"
                              onKeyDownCapture={handleKeyPress}
                            />
                          </div>
                          {emivalue === "NEW" && (
                            <>
                              <div class="mb-2">
                                <label class="name">Return Interest</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  value={data}
                                  readOnly
                                />
                              </div>
                            </>
                          )}
                          <div class="mb-2">
                            <div class="name">Payment Mode</div>
                            <select
                              class="form-select col-sm-12"
                              onChange={handler}
                              value={state.modes}
                              name="modes"
                              required="required"
                              onKeyDownCapture={handleKeyPress}
                            >
                              <option value="">Select Payment Mode</option>
                              <option value="UPI">UPI</option>
                              <option value="Cash">Cash</option>
                              <option value="Bank Transfer">
                                Bank Transfer
                              </option>
                            </select>
                          </div>
                          <div class="mb-2">
                            <label for="name">Date</label>
                            <input
                              class="form-control"
                              id="name"
                              type="date"
                              name="date"
                              value={state.date}
                              onChange={handler}
                              required="required"
                              onKeyDownCapture={handleKeyPress}
                            />
                          </div>
                        </>

                        {emivalue === "OLD" && (
                          <>
                            <div class="mb-2">
                              <label for="name">Interest</label>
                              <input
                                class="form-control"
                                id="name"
                                type="text"
                                onBlur={interestcal}
                                name="interest"
                                onChange={handler}
                                required="required"
                                onKeyDownCapture={handleKeyPress}
                              />
                            </div>
                            <div class="mb-2">
                              <label for="name">Interest Amount</label>
                              <input
                                class="form-control"
                                id="name"
                                type="text"
                                value={selectedValue}
                                name="i_amount"
                                onChange={handler}
                                required="required"
                                onKeyDownCapture={handleKeyPress}
                              />
                            </div>
                            <div class="mb-2">
                              <label for="name">Total Amount</label>
                              <input
                                class="form-control"
                                id="name"
                                type="number"
                                value={total}
                                name="total"
                                onChange={handler}
                                required="required"
                                onKeyDownCapture={handleKeyPress}
                              />
                            </div>
                          </>
                        )}

                        <div
                          class="mt-6"
                          style={{ marginBottom: "250px !important" }}
                        >
                          <button
                            class="btn btn-primary"
                            onKeyDown={handleKeyPress}
                            type="submit"
                          >
                            Pay Installment
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bulk_emi;
