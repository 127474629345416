import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Header from "./header";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import Select from 'react-select';
import $ from 'jquery';
import { BASE_URL } from "./config";

const Extra = () => {

    const [selectedOption, setSelectedOption] = useState("");

    const [options, setOptions] = useState([]);

    const [selectedDate, setSelectedDate] = useState('');

    const handleDateChange = (options) => {
        setSelectedDate(options.value);
        var id = options.value.split(")")
        // //console.log(id[0])
        setSelectedOption(id[0])
    };


    const initialState = {
        installment: "",
        mode: "",
        type: "",
        date: ""
    }

    const [state, setState] = useState(initialState)
    const { installment, mode, type, date } = state;

    const [tableData, setTableData] = useState([]);

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                page: "AddMoney"
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios.post(BASE_URL + 'admin_select_user.php', deleteData).then(data => {
            const optionsData = data.data.result.map((item) => ({ value: item.id + ")  " + (item.name), label: item.id + ")  " + (item.name) }));
            setOptions(optionsData);
        })
            .catch(error => console.error(error));
        Web();
    }, []);

    let name, value
    const handler = (e) => {
        name = e.target.name;
        value = e.target.value;
        setState({ ...state, [name]: value });

    };


    var a_name = localStorage.getItem('user')
    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }





    // if (type === "ANNUAL") {
    //     var pay = installment
    //     var late = ""
    // } 

    const Check = (e) => {
        $('.btn').addClass('disabled');

        e.preventDefault();
        if (mode == "") {
            toast.error('Select Payment Mode', { position: toast.POSITION.TOP_RIGHT });

        } else {
            const token = localStorage.getItem('token');
            const dObject = {
                authorization: token, input: {
                    payment_mode: mode,
                    mid: selectedOption,
                    annual_charge: installment,
                    admin_name: a_name,
                    aid: aid,
                    auth: sesspass,
                    trans_type: "ANNUAL",
                    date: date,
                    total: installment


                }
            };
            const encodedData = btoa(JSON.stringify(dObject));
            const deleteData = { "data": encodedData }
            axios
                .post(BASE_URL + "admin_add_transaction.php", deleteData)
                .then(function (data) {
                    if (data.data.message === "radha") {
                        setState({ installment: "", type: "", mode: "", date: "" })
                        toast.success('Payment Successfully', { position: toast.POSITION.TOP_RIGHT });
                        setSelectedDate("")
                        $('.btn').removeClass('disabled');

                    } else {
                        toast.error('Fill All Details', { position: toast.POSITION.TOP_RIGHT });
                        $('.btn').removeClass('disabled')

                    }

                })
        }
    };





    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="page-body" style={{ marginLeft: "320px" }}>
                    <div class="container-fluid">
                        <div class="page-title">
                            <ToastContainer />
                            <div class="row">
                                <div class="col-sm-6">
                                    <h3>Extra Charges (Annual Charge)</h3>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to={'/'}><i data-feather="home"></i>Dashboard</Link></li>
                                        {/* <li class="breadcrumb-item">Extra Charges</li> */}
                                        <li class="breadcrumb-item active">Extra Charges (Annual Charge)</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="select2-drpdwn">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-header pb-0">
                                            <h5 class="card-title">Extra Charges (Annual Charge)</h5>
                                        </div>
                                        <div class="card-body o-hidden">
                                            <form onSubmit={Check}>

                                                {/* <input class="form-control" type="text" onChange={(e) => setPname(e.target.value)} onBlur={search} /> */}
                                                <div class="mb-2">
                                                    <div class="col-form-label">Select Name</div>
                                                    <Select
                                                        value={selectedDate}
                                                        onChange={handleDateChange}
                                                        options={options}
                                                        isSearchable={true}

                                                    />


                                                </div>
                                                <div class="mb-2">
                                                    <p>{selectedDate}</p>
                                                </div>
                                                <div class="mb-2">
                                                    <label for="name">Amount</label>
                                                    <input class="form-control" id="name" type="text" value={state.installment} name="installment" onChange={handler} required="required" onKeyDownCapture={handleKeyPress} />
                                                </div>
                                                {/* <div class="mb-2">
                                                    <div class="name">Payment Type</div>
                                                    <select class="form-select col-sm-12" onChange={handler} value={state.type} name="type" required="required" onKeyDownCapture={handleKeyPress}>
                                                        <option value="">Select Payment Type</option>
                                                        <option value="ANNUAL">Annual Charge</option>

                                                    </select>
                                                </div> */}
                                                <div class="mb-2">
                                                    <div class="name">Payment Mode</div>
                                                    <select class="form-select col-sm-12" onChange={handler} value={state.mode} name="mode" required="required" onKeyDownCapture={handleKeyPress}>
                                                        <option value="">Select Payment Mode</option>
                                                        <option value="UPI">UPI</option>
                                                        <option value="Cash">Cash</option>
                                                        <option value="Bank Transfer">Bank Transfer</option>
                                                    </select>
                                                </div>
                                                <div class="mb-2">
                                                    <label class="name">Date</label>
                                                    <input class="form-control" type="date" name="date" onChange={handler} value={state.date} placeholder="Select Date" required="required" onKeyDownCapture={handleKeyPress} />
                                                </div>

                                                <div class="mb-2">
                                                    <button class="btn btn-primary" onKeyDown={handleKeyPress} type="submit">Extra Charges</button>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Extra