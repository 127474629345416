import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Header from "./header";

import DataTable, { createTheme } from 'react-data-table-component';
import { BASE_URL } from "./config";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';



const Reportdhan = () => {

    const [selectedOption, setSelectedOption] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedId, setSelectedId] = useState("");
    const [tableData, setTableData] = useState([]);
    const [data, setData] = useState([]);
    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    useEffect(() => {
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                page: "dhanaddmoney"
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios.post(BASE_URL + 'admin_select_user.php', deleteData)
            .then(data => {
                const optionsData = data.data.result.map((item) => ({ value: item.id + ")  " + (item.name), label: item.id + ")  " + (item.name) }));
                setOptions(optionsData);
            })
            .catch(error => console.error(error));
        Web();
    }, []);

    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }



    const handleDateChange = (options) => {

        setSelectedDate(options.value);
        var id = options.value.split(")")
        setSelectedOption(id[0])

        abc(id[0])


    };


    const abc = (selectedId) => {


        if (tableData) {
            setTableData([]);
            setData([]);
        }
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                card: selectedId
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        console.log(BASE_URL)
        axios
            .post(BASE_URL + "report_dhan.php", deleteData)
            .then(function (data) {
                console.log(data.data)
                if (data.data.message == "radha") {
                    setData(data.data.main_result);
                }
                if (data.data.tmsg == "radha") {
                    setTableData(data.data.trans_result);
                    //console.log("fill")

                }
            })

            .catch(error => console.error(error))
    }

    // const columns = [
    //     { name: 'Date', selector: row => row['date'], sortable: true, filterable: true },
    //     { name: 'Installment', selector: row => row['amount'], sortable: true, filterable: true },
    //     { name: 'Bonus', selector: row => row['bonus'], sortable: true, filterable: true },
    //     { name: 'Payment Mode', selector: row => row['payment_mode'], sortable: true, filterable: true },
    //     { name: 'By', selector: row => row['by'], sortable: true, filterable: true },
    //     { name: 'Total', selector: row => row['total'], sortable: true, filterable: true, style: { fontWeight: 'bold' } },
    //     { name: 'Withdraw', selector: row => row['withdraw'], sortable: true, filterable: true, style: { fontWeight: 'bold' } },


    // ];
    const coolor = "INWITHDRAW"; // Change this value as needed
    const coolor1 = "Withdraw"; // Change this value as needed

    const columns = [
        { name: 'Date', selector: 'date', sortable: true, filterable: true },
        {
            name: 'Type',
            selector: 't_type',
            sortable: true,
            filterable: true,
            cell: (row) => (
                <div
                    className="badge"
                    style={{
                        backgroundColor:
                            row.t_type === coolor1
                                ? "#ea0b3596"
                                : row.t_type === "Regular"
                                    ? "#c3e0b8"
                                    : "gray", // You may want to set a default color or remove this line if not needed
                    }}
                >
                    {row['t_type']}
                </div>
            ),
            // cell: row => (
            //     <div >
            //         {row.t_type === coolor ? 'WITHDRAW' : row.t_type}
            //     </div>
            // ),
        },
        { name: 'Installment', selector: 'amount', sortable: true, filterable: true },
        {
            name: 'Bonus',
            selector: 'bonus',
            sortable: true,
            filterable: true,
        },
        { name: 'Payment Mode', selector: 'payment_mode', sortable: true, filterable: true },
        { name: 'By', selector: 'by', sortable: true, filterable: true },
        {
            name: 'Total',
            selector: 'total',
            sortable: true,
            filterable: true,
            style: { fontWeight: 'bold' },
        },
        {

            name: 'Delete', button: true, cell: (row) => (
                row.emi_count == row.emis &&
                <img src="assets/images/bin.png" data-bs-toggle="modal" data-original-title="test" onClick={() => setSelectedId(row.did)} data-bs-target="#exampleModal" height={20} width={20} />
            ),
        },
    ];

    // Define a function to conditionally apply styles to rows
    const conditionalRowStyles = [
        {
            when: row => row.t_type === coolor1 || row.t_type === coolor, // Replace coolor with your condition
            style: {
                backgroundColor: '#ea0b3596', // Set the background color for matching rows
            },
        },
        {
            when: row => row.t_type !== coolor1 || row.t_type === coolor, // Replace coolor with your condition
            style: {
                backgroundColor: '#c3e0b8', // Set the background color for non-matching rows
            },
        },
    ];


    const deletes = () => {

        const selectedRow = filteredData.find(row => row.did === selectedId);
        console.log(selectedId + "   " + selectedRow.did)
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": sesspass, "page": "transaction", "id": selectedRow.did } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "dhan_del_user.php", deleteData)
            // .then(response => response.json())
            .then(function (data) {
                if (data.data.message === "radha") {
                    toast.success('Data Deleted Successfully', { position: toast.POSITION.TOP_RIGHT });
                    abc(selectedId);
                } else {
                    toast.error('Data Not Delete', { position: toast.POSITION.TOP_RIGHT });

                }
            }
            )

            .catch(error => console.error(error))

    }


    const [searchText, setSearchText] = useState('');

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };
    const filteredData = tableData.filter((item) =>
        item.date.toString().includes(searchText) ||
        item.amount.toString().includes(searchText) ||
        item.by.toLowerCase().includes(searchText.toLowerCase()) ||
        item.bonus.toString().includes(searchText) ||
        item.withdraw.toString().includes(searchText) ||
        item.total.toString().includes(searchText) ||
        item.payment_mode.toLowerCase().includes(searchText.toLowerCase())
    );



    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">

                            <div class="modal-body">Are You Sure ?</div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" onClick={() => deletes()} data-bs-dismiss="modal">Yes</button>
                                <button class="btn btn-dark" type="button" data-bs-dismiss="modal">No</button>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-body" style={{ marginLeft: "320px" }}>
                    <ToastContainer />
                    <div class="container-fluid">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header pb-0">
                                    <h5>Dhanvarsha Report</h5><span></span>
                                </div>
                                <div class="card-body">
                                    <div class="mb-2">
                                        <div class="col-form-label">Select User</div>
                                        <Select
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            options={options}
                                            isSearchable={true}
                                            title="Transactions"

                                        />
                                        <p>{selectedDate}</p>
                                    </div>

                                    <div class=" general-widget">

                                        <div class="row">

                                            <div class="col-sm-6 col-lg-12">
                                                <div class="card o-hidden">
                                                    <div class="card-body">
                                                        <div class="d-flex static-widget" style={{ color: "green" }}>
                                                            <div class="flex-grow-1">
                                                                <h6 class="font-roboto" >Total Installment</h6>
                                                                <h4 class="mb-0 counter" > ₹ {data}</h4>
                                                            </div>
                                                            <svg class="fill-success" style={{ fill: 'green' }} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M22.5938 14.1562V17.2278C20.9604 17.8102 19.7812 19.3566 19.7812 21.1875C19.7812 23.5138 21.6737 25.4062 24 25.4062C24.7759 25.4062 25.4062 26.0366 25.4062 26.8125C25.4062 27.5884 24.7759 28.2188 24 28.2188C23.2241 28.2188 22.5938 27.5884 22.5938 26.8125H19.7812C19.7812 28.6434 20.9604 30.1898 22.5938 30.7722V33.8438H25.4062V30.7722C27.0396 30.1898 28.2188 28.6434 28.2188 26.8125C28.2188 24.4862 26.3263 22.5938 24 22.5938C23.2241 22.5938 22.5938 21.9634 22.5938 21.1875C22.5938 20.4116 23.2241 19.7812 24 19.7812C24.7759 19.7812 25.4062 20.4116 25.4062 21.1875H28.2188C28.2188 19.3566 27.0396 17.8102 25.4062 17.2278V14.1562H22.5938Z"></path>
                                                                <path d="M25.4062 0V11.4859C31.2498 12.1433 35.8642 16.7579 36.5232 22.5938H48C47.2954 10.5189 37.4829 0.704531 25.4062 0Z"></path>
                                                                <path d="M14.1556 31.8558C12.4237 29.6903 11.3438 26.9823 11.3438 24C11.3438 17.5025 16.283 12.1958 22.5938 11.4859V0C10.0492 0.731813 0 11.2718 0 24C0 30.0952 2.39381 35.6398 6.14897 39.8624L14.1556 31.8558Z"></path>
                                                                <path d="M47.9977 25.4062H36.5143C35.8044 31.717 30.4977 36.6562 24.0002 36.6562C21.0179 36.6562 18.3099 35.5763 16.1444 33.8444L8.13779 41.851C12.3604 45.6062 17.905 48 24.0002 48C36.7284 48 47.2659 37.9508 47.9977 25.4062Z"></path>
                                                            </svg>
                                                        </div>
                                                        <div class="progress-widget">
                                                            <div class="progress sm-progress-bar progress-animate">
                                                                <div class="progress-gradient-success" role="progressbar" style={{ width: '75%', ariaValuenow: '75', ariaValuemin: '00', ariaValuemax: '100' }}><span class="animate-circle"></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="table-responsive">

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            pagination
                                            subHeader
                                            // conditionalRowStyles={conditionalRowStyles}
                                            subHeaderComponent={
                                                <input type="text" placeholder="Search" class="form-control" style={{ width: "200px" }} value={searchText} onChange={handleSearch} />
                                            }

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default Reportdhan;