import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from './pages/login';
import Transaction from "./pages/transaction";
import Header from "./pages/header";
import Employe from "./pages/employe";
import Addmoney from "./pages/addmoney";
import Createloan from "./pages/createloan";
import Loan from "./pages/loan";
import Member from "./pages/member";
import Account from "./pages/account";
import Goldprice from "./pages/goldprice";
import Extra from "./pages/extra";
import Listemploye from "./pages/listemploye";
import Loanrequest from "./pages/loanrequest";
import Profile from "./pages/profile";
import Password from "./pages/password";
import User from "./pages/user";
import LoanEmi from "./pages/loanemi";
import Mandaluser from "./pages/mandaluser";
import Dhanuser from "./pages/dhanuser";
import Dhan_transaction from "./pages/dhan_transaction";
import Create_dhan_user from "./pages/dhan_create_user";
import Dhan_add_money from "./pages/dhan_add_money";
import Dhan_delete_log from "./pages/dhan_delete_log";
import Delete_log from "./pages/delete_log";
import Withdraw from "./pages/withdaw";
import Dhan_Withdraw from "./pages/dhan_withdraw";
import Reportmandal from "./pages/reportmandal";
import Reportdhan from "./pages/reportdhan";
import Advertise from "./pages/advertise";
import Tra_report from "./pages/transaction_report";
import AddProductsForm from "./pages/product";
import Bulk_emi from "./pages/bulk_emi";
import Interest_manage from "./pages/interest_manage";
import Annual_bonus from "./pages/annual_bonus";
import { LogOut } from "react-feather";
import Logout from "./pages/logout";
import Log from "./pages/log";
import ImageUpload from "./pages/sidebar";
import { Dots } from 'react-preloaders';
import EMIcount from "./pages/emi_count";
import Mandal_bulk from "./pages/bulk_user_emi_mandal";
import Dhan_bulk from "./pages/dhan_bulk_emi";
import DhanHeader from "./pages/DhanHead";
import DhanAccount from "./pages/dhanAcoount";
import GoogleTranslate from "./pages/tt";
import SakhiTransaction from "./sakhi/transaction";
import SakhiAddmoney from "./sakhi/addmoney";
import Sakhi_bulk from "./sakhi/bulk_user_emi_mandal";
import Sakhiuser from "./sakhi/mandaluser";
import SakhiMember from "./sakhi/member";
import SakhiLoan from "./sakhi/loan";
import SakhiLoanEmi from "./sakhi/loanemi";
import SakhiCreateloan from "./sakhi/createloan";
import Sakhi_Bulk_emi from "./sakhi/bulk_emi";
import SakhiLoanrequest from "./sakhi/loanrequest";
import Sakhi_Interest_manage from "./pages/interest_manage";
import SakhiWithdraw from "./sakhi/withdaw";
import SakhiReport from "./sakhi/reportmandal";
import SakhiHeader from "./sakhi/header";
import SakhiAccount from "./sakhi/account";
import CreateDhanloan from "./pages/dhan_loan";
import DhanLoanEmi from "./pages/dhan_add_emi";
import DhanLoan from "./pages/dhanloan";
import DhanLoanrequest from "./pages/dhanloanrequest";
import ReportmandalMaster from "./pages/masterMandalReport";
import ReportdhanlMaster from "./pages/masterDhanReport";
import ReportsakhiMaster from "./sakhi/masterSakhiReport";



function App() {
  const ab = localStorage.getItem('aid')

  return (
    <Router>


      <div>
        <Switch>
          {/* <Route exact path="/"><GoogleTranslate /> </Route> */}
          <Route exact path="/"><Login /> </Route>
          <Route path="/account"><Header /><Account /> </Route>
          <Route path="/profile"><Header /><Profile /> </Route>
          <Route path='/logout' ><Header /><LogOut /></Route>
          <Route path='/transaction'><Header /><Transaction /></Route>
          <Route path='/emi_count' ><Header /><EMIcount /></Route>
          <Route path='/addmoney'><Header /><Addmoney /></Route>
          <Route path='/loanemi'><Header /><LoanEmi /></Route>
          <Route path='/extracharges'><Header /><Extra /></Route>
          <Route path='/member'><Header /><Member /></Route>
          <Route path='/goldprice'><Header /><Goldprice /></Route>
          <Route path='/createloan'><Header /><Createloan /></Route>
          <Route path='/addemploye'><Header /><Employe /></Route>
          <Route path='/advertise'><Header /><Advertise /></Route>
          <Route path='/mandal_user_bulk_emi'><Header /><Mandal_bulk /></Route>
          <Route path='/bulk_emi' ><Header /><Bulk_emi /></Route>
          <Route path='/loanrequest' ><Header /><Loanrequest /></Route>
          <Route path='/mandaluser'><Header /><Mandaluser /></Route>
          <Route path='/employe_details'><Header /><Listemploye /></Route>
          <Route path='/loan' ><Header /><Loan /></Route>
          <Route path='/withdraw'><Header /><Withdraw /></Route>
          <Route path='/delete_log' ><Header /><Delete_log /></Route>
          <Route path='/transaction_report'><Header /><Tra_report /></Route>
          <Route path='/interest_manage'><Header /><Interest_manage /></Route>
          <Route path='/annual_bonus'><Header /><Annual_bonus /></Route>
          <Route path='/mandalreport'><Header /><Reportmandal /></Route>
          <Route path='/mandal_master_report'><Header /><ReportmandalMaster /></Route>
          
         



          <Route exact path="/dhan_account"><DhanHeader /><DhanAccount /> </Route>
          <Route path="/create_dhan_user"><DhanHeader /><Create_dhan_user /></Route>
          <Route path="/dhanuser"><DhanHeader /><Dhanuser /></Route>
          <Route path='/dhan_transaction'><DhanHeader /><Dhan_transaction /></Route>
          <Route path='/dhan_add_money' ><DhanHeader /><Dhan_add_money /></Route>
          <Route path='/dhan_delete_log' ><DhanHeader /><Dhan_delete_log /></Route>
          <Route path='/dhan_Withdraw'><DhanHeader /><Dhan_Withdraw /></Route>
          <Route path='/dhanreport' ><DhanHeader /><Reportdhan /></Route>
          <Route path='/dhan_user_bulk_emi' ><DhanHeader /><Dhan_bulk /></Route>
          <Route path='/dhan_loan_create' ><DhanHeader /><CreateDhanloan /></Route>
          <Route path='/dhan_loan_emi' ><DhanHeader /><DhanLoanEmi /></Route>
          <Route path='/dhan_loan' ><DhanHeader /><DhanLoan /></Route>
          <Route path='/dhan_loan_request' ><DhanHeader /><DhanLoanrequest /></Route>
          <Route path='/dhan_master_report' ><DhanHeader /><ReportdhanlMaster /></Route>







          <Route path='/sakhi_account'><SakhiHeader /><SakhiAccount /></Route>
          <Route path='/sakhi_transaction'><SakhiHeader /><SakhiTransaction /></Route>
          <Route path='/sakhi_addmoney'><SakhiHeader /><SakhiAddmoney /></Route>
          <Route path='/sakhi_bulk_installment'><SakhiHeader /><Sakhi_bulk /></Route>
          <Route path='/sakhi_user'><SakhiHeader /><Sakhiuser /></Route>
          <Route path='/sakhi_member'><SakhiHeader /><SakhiMember /></Route>
          <Route path='/sakhi_loan'><SakhiHeader /><SakhiLoan /></Route>
          <Route path='/sakhi_loanemi'><SakhiHeader /><SakhiLoanEmi /></Route>
          <Route path='/sakhi_createloan'><SakhiHeader /><SakhiCreateloan /></Route>
          <Route path='/sakhi_bulk_emi' ><SakhiHeader /><Sakhi_Bulk_emi /></Route>
          <Route path='/sakhi_loanrequest' ><SakhiHeader /><SakhiLoanrequest /></Route>
          <Route path='/sakhi_interest_manage'><SakhiHeader /><Sakhi_Interest_manage /></Route>
          <Route path='/sakhi_withdraw'><SakhiHeader /><SakhiWithdraw /></Route>
          <Route path='/sakhi_report'><SakhiHeader /><SakhiReport /></Route>
          <Route path='/sakhi_master_report'><SakhiHeader /><ReportsakhiMaster /></Route>



        </Switch>
      </div>
    </Router >

  );
}

export default App;
