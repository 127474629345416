import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Header from "./header";

import DataTable, { createTheme } from 'react-data-table-component';
import { data } from "jquery";
import { BASE_URL } from "./config";
import { ToastContainer, toast } from "react-toastify";
import Loader from "./loader";


const Dhan_delete_log = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [selectedId, setSelectedId] = useState("");
    const [tableData, setTableData] = useState([]);
    const [loandata, setLoandata] = useState(false)
    useEffect(() => {
        const type = localStorage.getItem("type");
        if (type == "1") {
            setLoandata(true)
        }
        Web();
        abc();
    }, []);


    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }



    const abc = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": sesspass, "page": "dhan" } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "delete_log.php", deleteData)
            // .then(response => response.json())
            .then(function (data) {
                if (data.data.message == "radha") {
                    setTableData(data.data.result);
                    setIsLoading(false)
                } else {
                    setIsLoading(false)

                }
            })

            .catch(error => console.error(error))
    }


    const columns = [

        { name: 'Id', selector: row => row['id'], sortable: true, filterable: true },
        { name: 'Date', selector: row => row['date'], sortable: true, filterable: true },
        { name: 'Delete Id', selector: row => row['base_id'], sortable: true, filterable: true },
        { name: 'Delete Type', selector: row => row['delete_type'], sortable: true, filterable: true },
        { name: 'Name', selector: row => row['name'], sortable: true, filterable: true },
        { name: 'Phone', selector: row => row['phone'], sortable: true, filterable: true },
        { name: 'Type', selector: row => row['t_type'], sortable: true, filterable: true },
        { name: 'Dhan Id', selector: row => row['did'], sortable: true, filterable: true },
        { name: 'Transaction Id', selector: row => row['tid'], sortable: true, filterable: true },
        // { name: 'Withdraw', selector: row => row['withdraw'], sortable: true, filterable: true },
        { name: 'Amount', selector: row => row['amount'], sortable: true, filterable: true },
        { name: 'Bonus', selector: row => row['bonus'], sortable: true, filterable: true },
        { name: 'Total', selector: row => row['total'], sortable: true, filterable: true },
        { name: 'Payment Mode', selector: row => row['payment_mode'], sortable: true, filterable: true },
        { name: 'EMI', selector: row => row['emis'], sortable: true, filterable: true },



    ];
    const [searchText, setSearchText] = useState('');

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };
    const filteredData = tableData.filter((item) =>
        // item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.delete_type.toLowerCase().includes(searchText.toLowerCase()) ||
        item.date.toString().includes(searchText) ||
        item.did.toString().includes(searchText)
        // item.phone.toString().includes(searchText) 
    );


    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="page-body" style={{ marginLeft: "320px" }}>
                    <ToastContainer />
                    {loandata ? <div class="container-fluid general-widget card">
                        <img src="assets/images/page.jpg" style={{ height: "500px", width: "500px", margin: "auto" }} />
                        <h3 class="card-title" style={{ textAlign: "center", margin: "45px", color: "red" }}>You are not authorized to use this page</h3>

                    </div>
                        : <>
                            <div class="container-fluid">
                                <div class="col-sm-12">
                                    <div class="card" style={{ minHeight: "250px" }}>
                                        <div class="card-header pb-0">
                                            <h5>Dhanvarsha Delete log</h5><span></span>
                                        </div>
                                        {isLoading ? (
                                            <Loader />
                                        ) : (<>
                                            <div class="card-body">
                                                <div class="table-responsive">

                                                    <DataTable
                                                        columns={columns}
                                                        data={filteredData}
                                                        pagination
                                                        subHeader
                                                    // subHeaderComponent={
                                                    //     <input type="text" placeholder="Search" value={searchText} onChange={handleSearch} />
                                                    // }

                                                    />
                                                </div>
                                            </div>
                                        </>)}
                                    </div>
                                </div>
                            </div>

                        </>}
                </div>



            </div>
        </div>
    )
}

export default Dhan_delete_log;