import React from "react";

import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import Select from 'react-select';
import $, { event } from 'jquery'
import { BASE_URL } from "./config";


const Mandal_bulk = () => {
    const margin = localStorage.getItem("margin")
    var aid = localStorage.getItem("aid");
    const web = localStorage.getItem("web")
    const a_name = localStorage.getItem("user")
    const token = localStorage.getItem('token');
    var pass = localStorage.getItem("pass");

    //console.log(token)


    useEffect(() => {
        // init();
        Web();
    }, []);


    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": web } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };


    const initialState = {
        member: "",
        names: "",
        card: "",
        mobile: "",
        modes: "",
        date: "",
        msg: ""
    };
    const [tableData, setTableData] = useState([]);
    const [ids, setIds] = useState([]);
    const [state, setState] = useState(initialState);
    const { member, names, card, mobile, modes, date, msg } = state;
    const [data, setData] = useState("")
    const [total, setTotal] = useState("")


    const Check = (idss) => {
        $(".btn").addClass("disabled");
        console.log(idss)
        //console.log(tableData[0].id)

        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "data": idss, "payment_mode": modes, "date": date, "aid": aid, "auth": pass, "admin_name": a_name } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "admin_mandal_bulk_emi.php", deleteData)
            .then(function (data) {
                console.log(data)
                if (data.data.message == "radha") {
                    toast.success("Transaction Done", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    $(".btn").removeClass("disabled");
                    setState({ modes: "", date: "", idss: "" })
                    setTotal("")
                    // init();
                } else {
                    toast.error("Transaction Not Done", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    $(".btn").removeClass("disabled");
                }
            })
            .catch(error => console.error(error));
    };


    let name, value;
    const handler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState({ ...state, [name]: value });
        const numbers = value.split(',').map(num => num.trim());
        const isValid = numbers.every(num => num.length < 5 && !isNaN(num));

        if (isValid) {
            const numberArray = numbers.map(Number);
            setData(numberArray);
        } else {
            setData("false");
        }

    };



    const Edit = () => {
        //console.log("edit")
        setTotal("")

        // state.msg = ""
    }

    // setData("")

    const Users = () => {
        const token = localStorage.getItem('token');
        //console.log("click")
        const dObject = { authorization: token, input: { "aid": aid, "auth": pass, "data": data } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "mandal_bulk_user_emi.php", deleteData)
            .then(function (data) {
                //console.log(data)
                if (data.data.message === "Formate") {
                    toast.error("Please Enter User Id in Valid Formate", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else if (data.data.message === "No records found") {
                    toast.error("User Id Not Valid", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    setTableData(data.data.result)
                    setTotal(data.data.total)
                }
            }
            )
            .catch(error => console.error(error));
    }

    return (
        <>
            <div>
                <div class="page-body-wrapper">
                    <div class="page-body" style={{ marginLeft: "320px" }}>
                        <div class="container-fluid">
                            <div class="page-title">
                                <ToastContainer />
                                <div class="row">
                                    <div class="col-sm-6">
                                        <h3>Mandal Bulk Installment</h3>
                                    </div>
                                    <div class="col-sm-6">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item">
                                                <Link to={"/"}>
                                                    <i data-feather="home"></i>Dashboard
                                                </Link>
                                            </li>
                                            <li class="breadcrumb-item">Mandal Bulk Installment</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="container-fluid" style={{ paddingBottom: "300px" }}>
                            <div class="select2-drpdwn">
                                <div class="row">
                                    <div class="col-md-12" style={{ height: "300px" }}>
                                        <div class="card">
                                            <div class="card-header pb-0">
                                                <h5 class="card-title">Mandal Bulk Installment</h5>
                                            </div>

                                            <div class="card-body o-hidden">

                                                {!total ? (<>
                                                    <div class="mb-2">
                                                        <label for="name">Mandal Id</label>
                                                        <textarea
                                                            class="form-control"
                                                            height="4"
                                                            onChange={handler}
                                                            onKeyDownCapture={handleKeyPress}
                                                            value={state.msg}
                                                            name="msg"
                                                            placeholder="Mandal Id With Comma (1,2,3)"></textarea>

                                                    </div>
                                                    <div
                                                        class="mt-6"
                                                        style={{ marginBottom: "250px !important" }}
                                                    >
                                                        <button
                                                            class="btn btn-primary"
                                                            onKeyDown={handleKeyPress}
                                                            type="submit"
                                                            onClick={Users}
                                                        >
                                                            Next
                                                        </button>
                                                    </div></>
                                                ) : ""}

                                                {!total ? "" : (<>
                                                    <div
                                                        class="mt-6"
                                                        style={{ marginBottom: "10px", marginTop: "10px" }}
                                                    >
                                                        <div class="row">
                                                            <div class="col-md-11 col-12">
                                                                {tableData.map((row, index) => (
                                                                    <>
                                                                        <div key={index}>
                                                                            <p><b>{row.id}</b> - {row.name}</p>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                                {/* {total} */}
                                                            </div>
                                                            <div class="col-md-1 col-12">
                                                                <div
                                                                    class="mt-6"
                                                                    style={{ marginBottom: "250px !important" }}
                                                                >
                                                                    <b class="btn btn-primary"
                                                                        onClick={Edit}
                                                                    >
                                                                        Edit
                                                                    </b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mb-2">
                                                        <div class="name">Payable Amount</div>
                                                        <input type="text" class="form-control" value={total} disabled />
                                                    </div>
                                                    <div class="mb-2">
                                                        <div class="name">Payment Mode</div>
                                                        <select
                                                            class="form-select col-sm-12"
                                                            onChange={handler}
                                                            value={state.modes}
                                                            name="modes"
                                                            required="required"
                                                            onKeyDownCapture={handleKeyPress}
                                                        >
                                                            <option value="">Select Payment Mode</option>
                                                            <option value="UPI">UPI</option>
                                                            <option value="Cash">Cash</option>
                                                            <option value="Bank Transfer">
                                                                Bank Transfer
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="mb-2">
                                                        <label for="name">Date</label>
                                                        <input
                                                            class="form-control"
                                                            id="name"
                                                            type="date"
                                                            name="date"
                                                            value={state.date}
                                                            onChange={handler}
                                                            required="required"
                                                            onKeyDownCapture={handleKeyPress}
                                                        />
                                                    </div>

                                                    <div
                                                        class="mt-12"
                                                        style={{ marginBottom: "250px !important", marginTop: "12px" }}
                                                    >
                                                        <button
                                                            class="btn btn-primary"
                                                            onKeyDown={handleKeyPress}
                                                            type="submit"
                                                            onClick={() => Check(tableData.map(row => row.id))}
                                                        >
                                                            Pay Installment
                                                        </button>
                                                    </div>

                                                </>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Mandal_bulk;