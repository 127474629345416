import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Header from "./header";

import DataTable, { createTheme } from 'react-data-table-component';
import { data } from "jquery";
import { BASE_URL } from "./config";
import { ToastContainer, toast } from 'react-toastify';

import Loader from "./loader";

const Mandaluser = () => {
    const [isLoading, setIsLoading] = useState(true);

    const [selectedId, setSelectedId] = useState("");
    const [tableData, setTableData] = useState([]);
    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    var user = localStorage.getItem('user')
    const initialState = {
        ename: "",
        phone: "",

    }

    const [state, setState] = useState(initialState)
    const { ename, phone } = state;

    let name, value
    const handler = (e) => {
        name = e.target.name;
        value = e.target.value;
        setState({ ...state, [name]: value });

    };

    useEffect(() => {
        Web();
        abc();
    }, []);

    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")


    const [selectedRow, setSelectedRow] = useState(null);

    const handleEditClick = (row) => {
        setSelectedRow(row);
        setState({ ...state, ename: row.name, phone: row.phone });

    };

    const handleDeleteClick = (row) => {
        setSelectedRow(row);
        // Show your modal for deletion with the selected row's data
        // You can use a CSS framework like Bootstrap or a custom modal component.
    };


    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                //console.log(data.data)
                const abc = data.data.web
                //console.log(abc + " === " + web)
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);
                }
            }
            )
            .catch(error => console.error(error));
    }



    const abc = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": sesspass, "page": "mandal" } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "user_list.php", deleteData)
            // .then(response => response.json())
            .then(function (data) {
                //console.log(data)
                if (data.data.message == "radha") {
                    setTableData(data.data.trans_result);
                    setIsLoading(false);

                }
                setIsLoading(false);

            })

            .catch(error => console.error(error))
    }

    const deletes = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": sesspass, "page": "mandal", "id": selectedRow.id, "name": user } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "del_mandal_user.php", deleteData)
            // .then(response => response.json())
            .then(function (data) {
                if (data.data.message === "radha") {
                    toast.success('Data Deleted Successfully', { position: toast.POSITION.TOP_RIGHT });
                    abc();
                } else {
                    toast.error('Data Not Delete', { position: toast.POSITION.TOP_RIGHT });

                }
            }
            )

            .catch(error => console.error(error))

    }


    const edits = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": sesspass, "page": "mandal", "id": selectedRow.id, "ename": ename, "phone": phone } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "edit_user.php", deleteData)
            // .then(response => response.json())
            .then(function (data) {
                if (data.data.message === "updated") {
                    toast.success('User Edited Successfully', { position: toast.POSITION.TOP_RIGHT });
                    abc();
                } else {
                    toast.error('User Not Edited', { position: toast.POSITION.TOP_RIGHT });

                }
            }
            )

            .catch(error => console.error(error))

    }


    const columns = [
        { name: 'Card No', selector: row => row['id'], sortable: true, filterable: true },
        { name: 'Name', selector: row => row['name'], sortable: true, filterable: true },
        { name: 'Phone', selector: row => row['phone'], sortable: true, filterable: true },

        {
            name: 'Edit', button: true, cell: (row) => (
                <img src="assets/images/pen.png" data-bs-toggle="modal" data-original-title="test" onClick={() => handleEditClick(row)} data-bs-target="#exampleModal1" height={20} width={20} />
            ),
        },
        {
            name: 'Delete', button: true, cell: (row) => (
                <img src="assets/images/bin.png" data-bs-toggle="modal" data-original-title="test" onClick={() => handleDeleteClick(row)} data-bs-target="#exampleModal" height={20} width={20} />
            ),
        },

    ];
    const [searchText, setSearchText] = useState('');

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };
    const filteredData = tableData.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.phone.toString().includes(searchText) ||
        item.id.toString().includes(searchText)
    );



    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">

                            <div class="modal-body">Are You Sure ?</div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" onClick={() => deletes()} data-bs-dismiss="modal">Yes</button>
                                <button class="btn btn-dark" type="button" data-bs-dismiss="modal">No</button>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">

                            <div class="modal-body">
                                {selectedRow && (
                                    <>
                                        <h3 style={{ textAlign: "center" }}>Edit User</h3>
                                        <label>Id</label><input type="text" class="form-control" value={selectedRow.id} readOnly onChange={handler} name="id" />
                                        <label>Name</label><input type="text" class="form-control" value={ename} onChange={handler} name="ename" />
                                        <label>Mobile Number</label><input type="text" class="form-control" maxLength={10} value={phone} onChange={handler} name="phone" />
                                    </>)}
                            </div>

                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" onClick={() => edits()} data-bs-dismiss="modal">Yes</button>
                                <button class="btn btn-dark" type="button" data-bs-dismiss="modal">No</button>

                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />


                <div class="page-body" style={{ marginLeft: "320px" }}>

                    <div class="container-fluid">
                        <div class="col-sm-12">
                            <div class="card" style={{ minHeight: "250px" }}>
                                <div class="card-header pb-0">
                                    <h5>Mandal User</h5><span></span>
                                </div>
                                {isLoading ? (
                                    <Loader />
                                ) : (<>
                                    <div class="card-body">
                                        <div class="table-responsive">

                                            <DataTable
                                                columns={columns}
                                                data={filteredData}
                                                pagination
                                                subHeader
                                                subHeaderComponent={
                                                    <input type="text" placeholder="Search" class="form-control" style={{ width: "200px" }} value={searchText} onChange={handleSearch} />
                                                }

                                            />
                                        </div>
                                    </div>
                                </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>





            </div>
        </div>
    )
}

export default Mandaluser;