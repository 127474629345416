import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Header from "./header";

import DataTable, { createTheme } from 'react-data-table-component';
import { data } from "jquery";
import { BASE_URL } from "./config";
import { ToastContainer, toast } from "react-toastify";
import Loader from "./loader";


const Delete_log = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [selectedId, setSelectedId] = useState("");
    const [tableData, setTableData] = useState([]);
    const [loandata, setLoandata] = useState(false)
    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')

    useEffect(() => {
        const type = localStorage.getItem("type");
        if (type == "1") {
            setLoandata(true)
        }
        Web();
        abc();
    }, []);

    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }




    const abc = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": sesspass, "page": "mandal" } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        //console.log(deleteData)
        axios
            .post(BASE_URL + "delete_log.php", deleteData)
            // .then(response => response.json())
            .then(function (response) {
                //console.log(response.data)
                if (response.data.message == "radha") {
                    setTableData(response.data.result);
                    setIsLoading(false);

                }
                setIsLoading(false);

            })

            .catch(error => console.error(error))
    }

    //console.log(tableData)



    const columns = [
        { name: 'Id', selector: row => row['id'], sortable: true, filterable: true },
        { name: 'Date', selector: row => row['date'], sortable: true, filterable: true },
        { name: 'Delete id', selector: row => row['delete_id'], sortable: true, filterable: true },
        { name: 'Delete type', selector: row => row['delete_type'], sortable: true, filterable: true },
        { name: 'Mandal Id', selector: row => row['mandal_id'], sortable: true, filterable: true },
        // { name: 'Name', selector: row => row['m_name'], sortable: true, filterable: true },
        // { name: 'Phone', selector: row => row['m_phone'], sortable: true, filterable: true },
        // { name: 'Loan Id', selector: row => row['l_id'], sortable: true, filterable: true },
        { name: 'Amount', selector: row => row['l_amount'], sortable: true, filterable: true },
        { name: 'Loan Remain', selector: row => row['l_remaining'], sortable: true, filterable: true },
        { name: 'Loan Interest', selector: row => row['l_interest'], sortable: true, filterable: true },
        { name: 'Loan Month', selector: row => row['l_month'], sortable: true, filterable: true },
        { name: 'Loan Remain Month', selector: row => row['l_rem_month'], sortable: true, filterable: true },
        { name: 'Loan EMI', selector: row => row['l_emi'], sortable: true, filterable: true },
        // { name: 'Transaction Id', selector: row => row['t_id'], sortable: true, filterable: true },
        { name: 'Tra Type', selector: row => row['t_type'], sortable: true, filterable: true },
        { name: 'Tra Withdraw', selector: row => row['t_withdraw'], sortable: true, filterable: true },
        { name: 'Tra Installment', selector: row => row['t_regular_installment'], sortable: true, filterable: true },
        { name: 'Tra Loan Id', selector: row => row['t_loan_id'], sortable: true, filterable: true },
        { name: 'Tra EMI', selector: row => row['t_emi'], sortable: true, filterable: true },
        { name: 'Tra EMI Interest', selector: row => row['t_emi_interest'], sortable: true, filterable: true },
        { name: 'Tra EMI Percent', selector: row => row['t_emi_percent'], sortable: true, filterable: true },
        { name: 'Tra Late Payment', selector: row => row['t_late_pay'], sortable: true, filterable: true },
        { name: 'Tra Annual Charge', selector: row => row['t_annual'], sortable: true, filterable: true },
        { name: 'Tra Payment Mode', selector: row => row['t_pay_mode'], sortable: true, filterable: true },
        { name: 'Tra Total', selector: row => row['t_total'], sortable: true, filterable: true },


    ];
    const [searchText, setSearchText] = useState('');

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };
    const filteredData = tableData.filter((item) =>
        item.m_name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.delete_type.toLowerCase().includes(searchText.toLowerCase()) ||
        item.date.toString().includes(searchText) ||
        item.mid.toString().includes(searchText) ||
        item.m_phone.toString().includes(searchText)

    );


    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="page-body" style={{ marginLeft: "320px" }}>
                    <ToastContainer />
                    {loandata ? <div class="container-fluid general-widget card">
                    <img src="assets/images/page.jpg" style={{ height: "500px", width: "500px", margin: "auto" }} />

                        <h3 class="card-title" style={{ textAlign: "center", margin: "45px", color: "red" }}>You are not authorized to use this page</h3>
                    </div>
                        : <>
                            <div class="container-fluid">
                                <div class="col-sm-12">
                                    <div class="card" style={{ minHeight: "250px" }}>
                                        <div class="card-header pb-0">
                                            <h5>Delete log</h5><span></span>
                                        </div>
                                        {isLoading ? (
                                            <Loader />
                                        ) : (<>
                                            <div class="card-body">
                                                <div class="table-responsive">

                                                    <DataTable
                                                        columns={columns}
                                                        data={filteredData}
                                                        pagination
                                                        subHeader
                                                    // subHeaderComponent={
                                                    //     <input type="text" placeholder="Search" value={searchText} onChange={handleSearch} />
                                                    // }

                                                    />
                                                </div>
                                            </div>
                                        </>)}
                                    </div>
                                </div>
                            </div>


                        </>}
                </div>
            </div>
        </div>
    )
}

export default Delete_log;