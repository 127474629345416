import React from "react";
import Header from "./header";

import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import Select from 'react-select';
import DataTable, { createTheme } from 'react-data-table-component';
import $, { event } from 'jquery'
import { BASE_URL } from "./config";
import Loader from "./loader";


const Tra_report = () => {
    const [isLoading,setIsLoading] = useState(true)
    const [selectedOption, setSelectedOption] = useState("");

    const [options, setOptions] = useState([]);

    const [selectedDate, setSelectedDate] = useState('');
    const [tableData, setTableData] = useState([]);
    const [loanData, setLoanData] = useState([]);
    const [data, setData] = useState([]);


    const handleDateChange = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: {} };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "transaction_report.php", deleteData)
            .then(function (data) {
                if (data.data.tmsg == "radha") {
                    setTableData(data.data.trans_result);
                    setIsLoading(false)
                }
                if (data.data.lmsg == "radha") {
                    setLoanData(data.data.loan_result);
                    setIsLoading(false)

                }
            })

            .catch(error => console.error(error))
    };



    const columns = [
        { name: 'Date', selector: row => row['date'], sortable: true, filterable: true },
        { name: 'Name', selector: row => row['name'], sortable: true, filterable: true },
        { name: 'Regular Installment', selector: row => row['regular_installment'], sortable: true, filterable: true },
        { name: 'EMI', selector: row => row['emi'], sortable: true, filterable: true },
        { name: 'Interest', selector: row => row['emi_interest'], sortable: true, filterable: true },
        { name: 'Payment Mode', selector: row => row['payment_mode'], sortable: true, filterable: true },
        { name: 'By', selector: row => row['by'], sortable: true, filterable: true },
        { name: 'Total', selector: row => row['total'], sortable: true, filterable: true, style: { fontWeight: 'bold' } },


    ];
    const [searchText1, setSearchText1] = useState('');

    const handleSearch1 = (e) => {
        setSearchText1(e.target.value);
    };

    const filteredData = tableData.filter((item) =>
        item.regular_installment.toString().includes(searchText1) ||
        item.emi_type.toLowerCase().includes(searchText1.toLowerCase()) ||
        item.name.toLowerCase().includes(searchText1.toLowerCase()) ||
        item.emi.toString().includes(searchText1) ||
        item.payment_mode.toLowerCase().includes(searchText1.toLowerCase()) ||
        item.by.toLowerCase().includes(searchText1.toLowerCase()) ||
        item.total.toString().includes(searchText1)

    );

    const [searchText, setSearchText] = useState('');

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const columns1 = [
        { name: 'Date', selector: row => row['date'], sortable: true, filterable: true },
        { name: 'Name', selector: row => row['name'], sortable: true, filterable: true },
        { name: 'Regular Installment', selector: row => row['regular_installment'], sortable: true, filterable: true },
        { name: 'EMI', selector: row => row['emi'], sortable: true, filterable: true },
        { name: 'Interest', selector: row => row['emi_interest'], sortable: true, filterable: true },
        { name: 'Payment Mode', selector: row => row['payment_mode'], sortable: true, filterable: true },
        { name: 'By', selector: row => row['by'], sortable: true, filterable: true },
        { name: 'Total', selector: row => row['total'], sortable: true, filterable: true, style: { fontWeight: 'bold' } },


    ];

    const filteredlData = loanData.filter((item) =>
        item.regular_installment.toString().includes(searchText) ||
        item.emi_type.toLowerCase().includes(searchText.toLowerCase()) ||
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.emi.toString().includes(searchText) ||
        item.payment_mode.toLowerCase().includes(searchText.toLowerCase()) ||
        item.by.toLowerCase().includes(searchText.toLowerCase()) ||
        item.total.toString().includes(searchText)
    );



    useEffect(() => {
        handleDateChange();
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "page": "AddMoney" } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios.post(BASE_URL + 'admin_select_user.php', deleteData)
            .then(data => {
                const optionsData = data.data.result.map((item) => ({ value: item.id + ")  " + (item.name), label: item.id + ")  " + (item.name) }));
                setOptions(optionsData);
            })
            .catch(error => console.error(error));
        Web();
    }, []);


    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }




    const Check = (event) => {

        event.preventDefault();


    };



    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="page-body" style={{ marginLeft: "320px" }}>
                    <div class="container-fluid">
                        <div class="page-title">
                            <div class="row">
                                <ToastContainer />

                                <div class="col-sm-6">
                                    <h3>Transaction Report</h3>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to={'/'}><i data-feather="home"></i></Link>Dashboard</li>
                                        <li class="breadcrumb-item">Transaction Report</li>
                                        {/* <li class="breadcrumb-item active">Add Money</li> */}
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="select2-drpdwn">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card" style={{minHeight:"250px"}}>
                                        <div class="card-header pb-0">
                                            <h5 class="card-title">Transaction Report</h5>
                                        </div>
                                        <div class="card-body o-hidden">
                                            <form onSubmit={Check}>

                                                {/* <div class="mb-2">
                                                    <div class="col-form-label">Select User</div>
                                                    <Select
                                                        value={selectedDate}
                                                        onChange={handleDateChange}
                                                        options={options}
                                                        isSearchable={true}
                                                        title="Transactions"

                                                    />
                                                    <p>{selectedDate}</p>
                                                </div> */}


                                                {isLoading ? (
                                                    <Loader />
                                                ) : (<>
                                                    <div class="mt-4">

                                                        <div class="card table-responsive">
                                                            <DataTable
                                                                columns={columns}
                                                                data={filteredData}
                                                                pagination
                                                                subHeader
                                                                subHeaderComponent={
                                                                    <input type="text" placeholder="Search" value={searchText1} class="form-control" style={{ width: "200px" }} onChange={handleSearch1} />
                                                                }

                                                            />

                                                        </div>
                                                        <div class="table-responsive ">

                                                            <DataTable
                                                                columns={columns1}
                                                                data={filteredlData}
                                                                pagination
                                                                subHeader
                                                                subHeaderComponent={
                                                                    <input type="text" placeholder="Search" value={searchText} class="form-control" style={{ width: "200px" }} onChange={handleSearch} />
                                                                }

                                                            />

                                                        </div>
                                                    </div>
                                                </>)}
                                            </form>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Tra_report