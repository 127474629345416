import React from "react";
import Header from "./header";

import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import DataTable, { createTheme } from "react-data-table-component";
import $, { event } from "jquery";
import { BASE_URL } from "./config";
import Loader from "./loader";
import * as XLSX from "xlsx";

const ReportmandalMaster = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const [options, setOptions] = useState([]);
  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2); // Months are zero-indexed
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };
  const dates = getCurrentDate;
  const [selectedDate, setSelectedDate] = useState("");
  const [tableData, setTableData] = useState([]);
  const [loanData, setLoanData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [fromDate, setFromDate] = useState("2022-01-01");
  const [toDate, setToDate] = useState(dates);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    abc();
  }, [toDate, fromDate, selectedOption]);

  const handleDateChange = (options) => {
    setSelectedDate(options.value);
    var id = options.value.split(")");
    setSelectedOption(id[0]);
    abc(id[0]);
    setUserName(id[1]);

    console.log(id[0]);
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };
  const abc = () => {
    setLoading(true);
    if (tableData) {
      setTableData([]);
      setData([]);
      setLoanData([]);
    }
    const token = localStorage.getItem("token");
    const dObject = { authorization: token };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios

      .post(BASE_URL + "admin_master_report.php", deleteData)
      .then(function (data) {
        console.log(data);
        if (data.data.message == "radha") {
          setData(data.data.main_result);
        }
        if (data.data.tmsg == "radha") {
          setTableData(data.data.trans_result);
          applyFilters(data.data.trans_result);
        }
        if (data.data.lmsg == "radha") {
          setLoanData(data.data.loan_result);
        }
        console.log(data.data.trans_result);
      })
      .catch((error) => console.error(error));
    setLoading(false);
  };

  const deletes = () => {
    const selectedRow = filteredData.find((row) => row.id === selectedId);
    const token = localStorage.getItem("token");
    const dObject = {
      authorization: token,
      input: {
        aid: aid,
        auth: sesspass,
        page: "transaction",
        id: selectedRow.id,
      },
    };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(BASE_URL + "del_mandal_user.php", deleteData)
      // .then(response => response.json())
      .then(function (data) {
        if (data.data.message === "radha") {
          toast.success("Data Deleted Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          abc(selectedOption);
        } else if (data.data.message === "FIRST") {
          toast.error("First Transaction can not delete", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Data Not Delete", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })

      .catch((error) => console.error(error));
  };

  const coolor = "Withdraw";

  const columns = [
    {
      name: "Date",
      selector: (row) => row["date"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Card No.",
      selector: (row) => row["mid"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Type",
      selector: (row) => row["trans_type"],
      sortable: true,
      filterable: true,
      cell: (row) => (
        <div
          className="badge"
          style={{
            backgroundColor:
              row.trans_type === coolor
                ? "#ea0b3596"
                : row.trans_type === "Regular"
                ? "#c3e0b8"
                : row.trans_type === "EMI" ||
                  row.trans_type == "Added Interest" ||
                  row.trans_type == "Withdraw Interest" ||
                  row.trans_type == "Return Interest" ||
                  row.trans_type == "Interest"
                ? "#76b3df"
                :row.trans_type === "CAPITAL INTEREST"
                                            ? "#a4ae1b94"
                : row.trans_type === "Late Payment Charge"
                ? "#ffce009e"
                : "gray", // You may want to set a default color or remove this line if not needed
          }}
        >
          {row["trans_type"]}
        </div>
      ),
    },
    {
      name: "Amount",
      selector: (row) => row["regular_installment"],
      sortable: true,
      filterable: true,
    },
    // { name: 'EMI', selector: row => row['emi'], sortable: true, filterable: true },
    {
      name: "Interest",
      selector: (row) => row["emi_interest"],
      sortable: true,
      filterable: true,
    },
    { name: 'Remark', selector: row => row['remark'], sortable: true, filterable: true, width: '250px' },

    {
      name: "Total",
      selector: (row) => row["total"],
      sortable: true,
      filterable: true,
      style: { fontWeight: "bold" },
    },
    {
      name: "Payment Mode",
      selector: (row) => row["payment_mode"],
      sortable: true,
      filterable: true,
    },
    // { name: 'Withdraw', selector: row => row['withdraw'], sortable: true, filterable: true },
    {
      name: "By",
      selector: (row) => row["by"],
      sortable: true,
      filterable: true,
    },
  
    // {
    //   name: "Delete",
    //   button: true,
    //   cell: (row) =>
    //     row.emi_type !== "FIRST" && (
    //       <img
    //         src="assets/images/bin.png"
    //         data-bs-toggle="modal"
    //         data-original-title="test"
    //         onClick={() => setSelectedId(row.id)}
    //         data-bs-target="#exampleModal"
    //         height={20}
    //         width={20}
    //       />
    //     ),
    // },
  ];

 

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
 
  const applyFilters = (tableData) => {
    setLoading(true);
    let filtered = tableData;
    if ((fromDate && toDate) || selectedOption || selectedOption == "") {
      filtered = filtered.filter((item) => {
        const itemDate = item.date;
        return (
          itemDate >= fromDate &&
          itemDate <= toDate &&
          (selectedOption == "" || item.mid == selectedOption)
        );
      });
    } else if (selectedOption) {
      filtered = filtered.filter((item) => item.mid === selectedOption);
    } else if (searchText) {
      filtered = filtered.filter(
        (item) =>
          item.regular_installment.toString().includes(searchText) ||
          item.emi.toString().includes(searchText) ||
          item.payment_mode.toLowerCase().includes(searchText.toLowerCase()) ||
          item.by.toLowerCase().includes(searchText.toLowerCase()) ||
          item.total.toString().includes(searchText)
      );
    }
    setFilteredData(filtered);
    setLoading(false);
  };

  const CustomSubHeader = ({ onExport }) => (
    <div className="custom-subheader">
      <button className="btn btn-primary" onClick={onExport}>
        Export to Excel
      </button>
    </div>
  );

  const headerMap = {
    date: "Date",
    mid: "Mandal Card No.",
    trans_type: "Type",
    regular_installment: "Amount",
    emi_interest: "Interest",
    remark:"Remark",
    total: "Total",
    payment_mode: "Payment Mode",
    by: "By",
  };

  const exportToExcel = (filteredData) => {
    // Prepare data for export with custom headers
    const formattedData = filteredData.map((item) => {
      const formattedItem = {};
      Object.keys(headerMap).forEach((col) => {
        formattedItem[headerMap[col]] = item[col];
      });
      return formattedItem;
    });
  
    // Create a new workbook
    const wb = XLSX.utils.book_new();
  
    // Prepare filter metadata
    const filterData = [
      ["","","","Mandal Master Report"],
      [`Date: ${fromDate} - ${toDate}`,"","","",`Mandal User: ${userName}`],
      [""], // Empty row for spacing
    ];
  
    // Create filter worksheet
    const filterWs = XLSX.utils.aoa_to_sheet(filterData);
  
    // Merge first 5 cells in the first row
    filterWs['merges'] = [
      XLSX.utils.decode_range('A1:H1') // Merge A1 to E1
    ];
  
    // Center align filter metadata
    filterWs['A1'].s = { alignment: { horizontal: 'center' } };
    filterWs['A2'].s = { alignment: { horizontal: 'center' } };
  
    // Convert formatted data to a worksheet
    const wsData = XLSX.utils.json_to_sheet(formattedData);
  
    // Add headers and set column widths for data
    const headers = columns.map((col) => col.name);
    XLSX.utils.sheet_add_aoa(wsData, [headers], { origin: "A1" });
    wsData["!cols"] = headers.map(() => ({ width: 50 })); // Set column widths
  
    // Append the filter metadata and data to the same worksheet
    const combinedData = XLSX.utils.sheet_to_json(filterWs, { header: 1 }).concat(
      XLSX.utils.sheet_to_json(wsData, { header: 1 })
    );
    const combinedWs = XLSX.utils.aoa_to_sheet(combinedData);
  
    // Append the combined worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, combinedWs, "Report");
  
    // Write the workbook to a file
    XLSX.writeFile(wb, "MandalReport.xlsx");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const dObject = { authorization: token, input: { page: "AddMoney" } };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(BASE_URL + "admin_select_user.php", deleteData)
      .then((data) => {
        const optionsData = data.data.result.map((item) => ({
          value: item.id + ")  " + item.name,
          label: item.id + ")  " + item.name,
        }));
        setOptions(optionsData);
      })
      .catch((error) => console.error(error));
    Web();
  }, []);

  var sesspass = localStorage.getItem("pass");
  var aid = localStorage.getItem("aid");
  var websess = localStorage.getItem("web");
  const web = localStorage.getItem("web");

  const Web = () => {
    const token = localStorage.getItem("token");
    const dObject = {
      authorization: token,
      input: { aid: aid, auth: websess },
    };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(BASE_URL + "web_auth.php", deleteData)
      .then(function (data) {
        const abc = data.data.web;
        if (abc !== web) {
          localStorage.setItem("user", "");
          localStorage.setItem("token", "");
          localStorage.setItem("pass", "");
          localStorage.setItem("aid", "");
          localStorage.setItem("margin", "");
          localStorage.setItem("web", "");
          toast.error("You can not use on multiple device at a same time", {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload(false);
        }
        //console.log(data)
      })
      .catch((error) => console.error(error));
  };

  const Check = (event) => {
    event.preventDefault();
  };

  const margin = localStorage.getItem("margin");

  return (
    <div>
      <div class="page-body-wrapper">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">Are You Sure ?</div>
              <div class="modal-footer">
                <button
                  class="btn btn-primary"
                  type="button"
                  onClick={() => deletes()}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  class="btn btn-dark"
                  type="button"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="page-body" style={{ marginLeft: "320px" }}>
          <div class="container-fluid">
            <div class="page-title">
              <div class="row">
                <ToastContainer />

                <div class="col-sm-6">
                  <h3>Mandal Master Report</h3>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to={"/"}>
                        <i data-feather="home"></i>
                      </Link>
                      Dashboard
                    </li>
                    <li class="breadcrumb-item">Mandal Master Report</li>
                    {/* <li class="breadcrumb-item active">Add Money</li> */}
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="select2-drpdwn">
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h5 class="card-title">Mandal Master Report</h5>
                    </div>
                    <div class="card-body o-hidden">
                      <form onSubmit={Check}>
                        <div class="row">
                          <div className="col-md-4">
                            <div class="col-form-label"> From Date:</div>
                            <input
                              type="date"
                              value={fromDate}
                              className="form-control"
                              onChange={handleFromDateChange}
                            />
                          </div>
                          <div className="col-md-4">
                            <div class="col-form-label"> To Date:</div>

                            <input
                              type="date"
                              className="form-control"
                              value={toDate}
                              style={{ width: "100%" }}
                              onChange={handleToDateChange}
                            />
                          </div>
                          <div class="col-md-4">
                            <div class="col-form-label">Select User</div>
                            <Select
                              value={selectedDate}
                              onChange={handleDateChange}
                              options={options}
                              isSearchable={true}
                              title="Transactions"
                            />
                            <p>{selectedDate}</p>
                          </div>
                        </div>

                        <div class="mt-4">
                          <div class="card table-responsive">
                            {loading == true ? (
                              <Loader />
                            ) : (
                              <DataTable
                                columns={columns}
                                data={filteredData}
                                pagination
                                subHeader
                                subHeaderComponent={
                                  <CustomSubHeader
                                    onExport={() => exportToExcel(filteredData)}
                                  />
                                }
                              />
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReportmandalMaster;
