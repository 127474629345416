import React from "react";
import Header from "./header";

import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import Select from 'react-select';
import $, { event } from 'jquery'
import { BASE_URL } from "./config";


const Interest_manage = () => {
    const [selectedOption, setSelectedOption] = useState("");
    const [action, setAction] = useState('');

    const [options, setOptions] = useState([]);

    const [selectedDate, setSelectedDate] = useState('');
    const [loanid, setLoanid] = useState('');
    const [amount, setAmount] = useState('');

    var a_name = localStorage.getItem('user')
    var aid = localStorage.getItem('aid')
    var pass = localStorage.getItem('pass')

    const handleDateChange = (options) => {
        setSelectedDate(options.value);
        const aa = options.value.split("-");
        setSelectedOption(aa[0])
        //console.log(selectedOption)
        setSelectedDate(aa[0] + ")  " + aa[3]);

        setLoanid(aa[5]);
        //console.log(aa[5])

    };




    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    const initialState = {
        installment: "",
        modes: "",
        date: "",
        b_value: "",
    }

    const [state, setState] = useState(initialState)
    const { installment, modes, date, b_value } = state;

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                page: "AddEmi",
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "loanemi_user.php", deleteData)
            // .then(response => response.json())
            .then((data) => {
                //console.log(data)
                const optionsData = data.data.result.map((item) => ({
                    value:
                        item.id +
                        "-" +
                        item.loan_type +
                        "-" +
                        item.emi +
                        "-" +
                        item.name +
                        "-" +
                        item.remaining +
                        "-" +
                        item.lid,
                    label:
                        item.id +
                        ")  " +
                        item.name +
                        "   [" +
                        item.remaining +
                        "/" +
                        item.amount +
                        "]",
                }));
                setOptions(optionsData);
            })
            .catch((error) => console.error(error));


        Web();
    }, []);

    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }



    let name, value
    const handler = (e) => {
        name = e.target.name;
        value = e.target.value;
        setState({ ...state, [name]: value });

    };

    const handleOptionChange = (event) => {
        setAction(event.target.value);
        // console.log(event.target.value)
    };



    const Check = (event) => {

        event.preventDefault();


        if (modes == "" || modes == "Select Payment Mode") {
            toast.error('Select Payment Mode', { position: toast.POSITION.TOP_RIGHT });
        } else {
            $('.btn').addClass('disabled')
            const token = localStorage.getItem('token');
            const dObject = {
                authorization: token, input: {
                    mid: selectedOption,
                    admin_name: a_name,
                    trans_type: "INTEREST",
                    aid: aid,
                    auth: pass,
                    interest: installment,
                    loan_id: loanid,
                    emi_type: action,
                    payment_mode: modes,
                    date: date,


                }
            };
            const encodedData = btoa(JSON.stringify(dObject));
            const deleteData = { "data": encodedData }
            axios
                .post(BASE_URL + "admin_add_transaction.php", deleteData)
                .then(function (data) {
                    //console.log(data);
                    if (data.data.message == "radha") {
                        setState({ installment: "", date: "", modes: "" });
                        setSelectedDate("");
                        toast.success('Transaction Successfully', { position: toast.POSITION.TOP_RIGHT });
                        $('.btn').removeClass('disabled');

                    } else {
                        toast.error('Transaction not complete ! First select name', { position: toast.POSITION.TOP_RIGHT });
                        $('.btn').removeClass('disabled');

                    }

                })
        }

    };



    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="page-body" style={{ marginLeft: "320px" }}>
                    <div class="container-fluid">
                        <div class="page-title">
                            <div class="row">
                                <ToastContainer />

                                <div class="col-sm-6">
                                    <h3>Interest Management</h3>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to={'/'}><i data-feather="home"></i></Link>Dashboard</li>
                                        <li class="breadcrumb-item">Interest Management</li>
                                        {/* <li class="breadcrumb-item active">Dhan Withdraw</li> */}
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="select2-drpdwn">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-header pb-0">
                                            <h5 class="card-title">Interest Management</h5>
                                        </div>
                                        <div class="card-body o-hidden">
                                            <form onSubmit={Check}>

                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="ADD"
                                                        checked={action === 'ADD'}
                                                        onChange={handleOptionChange}
                                                    />&nbsp;
                                                    Add Interest
                                                </label><br />
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="REMOVE"
                                                        checked={action === 'REMOVE'}
                                                        onChange={handleOptionChange}
                                                    />&nbsp;
                                                    Remove Interest
                                                </label>


                                                <div class="mb-2">
                                                    <div class="col-form-label">Select Name</div>
                                                    <Select
                                                        value={selectedDate}
                                                        onChange={handleDateChange}
                                                        options={options}
                                                        isSearchable={true}

                                                    />


                                                </div>
                                                <div class="mb-2">
                                                    <h5>{selectedDate}</h5>
                                                </div>
                                                <div class="mb-2">
                                                    <label for="name">Interest</label>
                                                    <input class="form-control" id="name" type="text" name="installment" value={state.installment} onChange={handler} required="required" onKeyDownCapture={handleKeyPress} />
                                                </div>



                                                <div class="mb-2">
                                                    <div class="name">Payment Mode</div>
                                                    <select class="form-select col-sm-12" onChange={handler} value={state.modes} name="modes" required="required" onKeyDownCapture={handleKeyPress}>
                                                        <option value="">Select Payment Mode</option>
                                                        <option value="UPI">UPI</option>
                                                        <option value="Cash">Cash</option>
                                                        <option value="Bank Transfer">Bank Transfer</option>
                                                    </select>
                                                </div>
                                                <div class="mb-2">
                                                    <label class="name">Date</label>
                                                    <input class="form-control" type="date" name="date" onChange={handler} value={state.date} placeholder="Select Date" required="required" onKeyDownCapture={handleKeyPress} />
                                                </div>

                                                <div class="mb-2">
                                                    <button class="btn btn-primary" onKeyDown={handleKeyPress} type="submit">SUBMIT</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Interest_manage