import React, { useRef } from 'react';
import { useState, useEffect } from "react";
import axios from "axios";
import Header from "./header";

import DataTable, { createTheme } from 'react-data-table-component';
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "./config";

import Loader from "./loader";


createTheme('my-theme', {
    text: {
        primary: '#333333',
        secondary: '#999999',
    },
    background: {
        default: '#f4f4f4',
    },
    context: {
        background: '#ffffff',
        text: '#666666',
    },
    divider: {
        default: '#cccccc',
    },
    action: {
        button: '#007aff',
        hover: '#007aff',
        disabled: '#cccccc',
    },
});


const SakhiLoanrequest = () => {
    const [isLoading, setIsLoading] = useState(true);

    const [status, setStatus] = useState('')
    const [id, setId] = useState('')
    const [uname, setUname] = useState('')
    const [amount, setAmount] = useState('')
    const [mid, setMid] = useState('')
    const [values, setValues] = useState('')
    const [selectedId, setSelectedId] = useState('');
    const [deleteval, setDeleteval] = useState('')
    const [isChecked, setIsChecked] = useState(false)
    const [ltype, setLtype] = useState("")


    const [tableData, setTableData] = useState([]);
    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')

    useEffect(() => {
        Web();
        abc();
    }, []);

    const initialState = {
        iamount: "",
        month: "",
        emi: "",
        loan: "",

    }


    const [state, setState] = useState(initialState)
    const { iamount, month, emi, loan } = state;

    let name, value
    const handler = (e) => {
        name = e.target.name;
        value = e.target.value;
        setState({ ...state, [name]: value });

    };

    const abc = () => {
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                aid: aid,
                auth: sesspass,
                page:"sakhi"
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "admin_loan_req.php", deleteData)

            .then(function (data) {
                //console.log(data)
                if (data.data.message === "radha") {
                    setTableData(data.data.result);
                    setIsLoading(false);

                }
                setIsLoading(false);

            })

            .catch(error => console.error(error))

    }

    var a_name = localStorage.getItem('user')
    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }



    function accept(row) {
        //console.log(`Button clicked for row ${row.id}`);
        setModalShow(true)
        setId(row.id)
        setAmount(row.amount)
        setUname(row.name)
        setMid(row.uid)
    }



    const InsertLoan = (e) => {

        e.preventDefault();
        if (iamount == '' || month == '' || emi == '' || loan == '') {
            const token = localStorage.getItem('token');
            const dObject = {
                authorization: token, input: {
                    status: "ACCEPT",
                    mid: mid,
                    loan_amount: amount,
                    a_name: a_name,
                    aid: aid,
                    auth: sesspass,
                    interest: iamount,
                    month: month,
                    emi: emi,
                    req_id: id,
                    loan_type: ltype
                }
            };
            const encodedData = btoa(JSON.stringify(dObject));
            const deleteData = { "data": encodedData }
            axios
                .post(BASE_URL + "admin_loan_status_handle.php", deleteData)
                .then(function (data) {
                    //console.log(data.data.message);
                    if (data.data.message == "radha") {
                        toast.success('Loan Request Accepted Successfully', { position: toast.POSITION.TOP_RIGHT });
                        abc();
                    } else {
                        toast.danger('Loan Not Accepted', { position: toast.POSITION.TOP_RIGHT });
                    }


                })
            // closeModal(e);
            setModalShow(false)


        }
    }


    function reject() {
        const selectedRow = filteredData.find(row => row.id === selectedId);
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                aid: aid,
                auth: sesspass,
                req_id: selectedRow.id,
                status: "REJECT"
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "admin_loan_status_handle.php", deleteData)
            .then(function (data) {
                if (data.data.message == "radha") {
                    // toast.success('Loan Request Rejected Successfully', { position: toast.POSITION.TOP_RIGHT });
                    abc();
                } else {
                    toast.danger('Loan Not Rejected', { position: toast.POSITION.TOP_RIGHT });
                }
            })

    }

    function deletes() {
        const selectedRow = filteredData.find(row => row.id === deleteval);
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                aid: aid,
                auth: sesspass,
                req_id: selectedRow.id,
                status: "DELETE",
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "admin_loan_status_handle.php", deleteData)
            .then(function (data) {
                if (data.data.message == "radha") {
                    // toast.success('Loan Request Deleted Successfully', { position: toast.POSITION.TOP_RIGHT });
                    abc();
                } else {
                    toast.danger('Loan Not Deleted', { position: toast.POSITION.TOP_RIGHT });
                }
            })

    }

    const [modalShow, setModalShow] = useState(false)

    const columns = [
        { name: 'Date', selector: row => row['date'], sortable: true },
        { name: 'Name', selector: row => row['name'], sortable: true },
        { name: 'Amount', selector: row => "₹ "+ row['amount'], sortable: true },
        { name: 'Purpose', selector: row => row['purpose'], sortable: true },
        { name: 'Reference Name', selector: row => row['ref_name'], sortable: true },
        { name: 'Status', selector: row => row['status'], sortable: true },
        {
            name: 'Accept', button: true, cell: (row) => (
                (row.status == 'PENDING') &&
                <img src="assets/images/accept.png" onClick={() => accept(row)} height={20} width={20} />
            ),
        },
        {
            name: 'Reject', button: true, cell: (row) => (
                row.status == 'PENDING' &&
                <img src="assets/images/cross.png" data-bs-toggle="modal" data-original-title="test" onClick={() => setSelectedId(row.id)} data-bs-target="#exampleModal" height={20} width={20} />
            ),
        },
        {
            name: 'Delete', button: true, cell: (row) => (
                (row.status == 'PENDING' || row.status == 'REJECT') &&
                <img src="assets/images/bin.png" data-bs-toggle="modal" data-original-title="test" onClick={() => setDeleteval(row.id)} data-bs-target="#exampleModaldelete" height={20} width={20} />
            ),
        },
    ];
    const [searchText, setSearchText] = useState('');
    //console.log(modalShow)

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        //console.log(searchText)
    };
    const Drop = (e) => {
        setSearchText(e.target.value)
        //console.log(e.target.value)
    }
    //console.log(tableData)



    const handleCheck = (e) => {
        // //console.log(e.target.checked)
        setIsChecked(e.target.checked);
        if (e.target.checked == true) {
            setLtype("OLD")
            // state.l_ty = "OLD";
        } else {
            setLtype("NEW")

            // state.l_ty = "NEW";
        }

    }
    const filteredData = tableData.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.purpose.toLowerCase().includes(searchText.toLowerCase()) ||
        item.ref_name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.date.toString().includes(searchText) ||
        item.amount.toString().includes(searchText) ||
        item.status.toLowerCase().includes(searchText.toLowerCase()),
    );






    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">

                            <div class="modal-body">Are You Sure ? You Want To Reject Loan Request</div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" onClick={() => reject()} data-bs-dismiss="modal">Yes</button>
                                <button class="btn btn-dark" type="button" data-bs-dismiss="modal">No</button>

                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />

                <div class="modal fade" id="exampleModaldelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">

                            <div class="modal-body">Are You Sure ? You Want To Delete Loan Request</div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" onClick={() => deletes()} data-bs-dismiss="modal">Yes</button>
                                <button class="btn btn-dark" type="button" data-bs-dismiss="modal">No</button>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body btn-showcase">

                    <div
                        className={`modal fade  ${modalShow ? 'show' : ''}`}
                        tabIndex="-1"
                        style={{ display: modalShow ? 'block' : 'none', backgroundColor: "#21211f4a" }}
                        aria-labelledby="exampleModalLabel"
                        role={modalShow ? "dialog" : ""}
                        aria-hidden={!modalShow}
                        data-bs-backdrop="static"
                        aria-modal={modalShow ? true : false}>
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel2">Loan Details</h5>
                                    <button class="btn-close" type="button" onClick={() => setModalShow(false)}></button>
                                </div>
                                <div class="modal-body">
                                    <form onSubmit={InsertLoan}>
                                        <div class="mb-3">
                                            <label class="col-form-label" for="recipient-name">Name</label>
                                            <input class="form-control" name="uname" onChange={handler} value={uname} type="text" placeholder={uname} />
                                        </div>
                                        <div class="mb-3">
                                            <label class="col-form-label" for="message-text">Loan Amount</label>
                                            <input class="form-control" name="loan" onChange={handler} type="text" placeholder={amount} />
                                        </div>
                                        <div class="mb-2">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" checked={isChecked} name="oldloan" id="flexCheckChecked" onChange={handleCheck} />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    OLD Loan
                                                </label>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label class="col-form-label" for="recipient-name">EMI Amount</label>
                                            <input class="form-control" name="emi" onChange={handler} type="text" />
                                        </div>
                                        <div class="mb-3">
                                            <label class="col-form-label" for="message-text">Month</label>
                                            <input class="form-control" name="month" onChange={handler} type="text" />
                                        </div>
                                        {isChecked ? "" : <>
                                            <div class="mb-3">
                                                <label class="col-form-label" for="recipient-name">Interest</label>
                                                <input class="form-control" name="iamount" onChange={handler} type="text" />
                                            </div></>}
                                        <input class="btn btn-primary" type="submit" value="Create Loan" name="submit" />
                                        <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="page-body" style={{ marginLeft: "320px" }}>
                    <div class="container-fluid">
                        <div class="col-sm-12">
                            <div class="card" style={{ minHeight: "250px" }}>
                                <div class="card-header pb-0">
                                    <h5>Sakhi Saheli Loan Requests</h5><span></span>
                                </div>

                                {isLoading ? (
                                    <Loader />
                                ) : (<>
                                    <div class="card-body row">
                                        <div class="col-md-3">
                                            <select class="form-control" onChange={Drop} name="status">
                                                <option value="">ALL</option>
                                                <option value="ACCEPT">ACCEPTED</option>
                                                <option value="REJECT">REJECTED</option>
                                                <option value="PENDING">PENDING</option>
                                            </select>
                                        </div>

                                        {tableData && <div class="table-responsive">
                                            <DataTable
                                                columns={columns}
                                                data={filteredData}
                                                pagination
                                                subHeader
                                                subHeaderComponent={
                                                    <input type="text" placeholder="Search" class="form-control" style={{ width: "200px" }} onChange={handleSearch} />
                                                }

                                            />
                                        </div>}
                                    </div>
                                </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default SakhiLoanrequest;