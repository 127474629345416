import React from "react";

import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "./config";
import { ToastContainer, toast } from "react-toastify";
import Loader from "./loader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from "react-data-table-component";

const DhanLoan = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [selectedId, setSelectedId] = useState("");
  const [tableData, setTableData] = useState([]);
  var sesspass = localStorage.getItem("pass");
  var aid = localStorage.getItem("aid");
  var websess = localStorage.getItem("web");

  useEffect(() => {
    Loan();
    Web();
  }, []);

  var sesspass = localStorage.getItem("pass");
  var aid = localStorage.getItem("aid");
  var websess = localStorage.getItem("web");
  const web = localStorage.getItem("web");

  const Web = () => {
    const token = localStorage.getItem("token");
    const dObject = {
      authorization: token,
      input: { aid: aid, auth: websess },
    };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(BASE_URL + "web_auth.php", deleteData)
      .then(function (data) {
        const abc = data.data.web;
        if (abc !== web) {
          localStorage.setItem("user", "");
          localStorage.setItem("token", "");
          localStorage.setItem("pass", "");
          localStorage.setItem("aid", "");
          localStorage.setItem("margin", "");
          localStorage.setItem("web", "");
          toast.error("You can not use on multiple device at a same time", {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload(false);
        }
        //console.log(data)
      })
      .catch((error) => console.error(error));
  };

  const Loan = () => {
    const token = localStorage.getItem("token");
    const dObject = {
      authorization: token,
      input: {
        aid: aid,
        auth: sesspass,
        page: "loan",
      },
    };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(BASE_URL + "dhan_admin_account.php", deleteData)
      .then(function (data) {
        //console.log(data)
        if (data.data.message == "radha") {
          setTableData(data.data.loan_result);
          setIsLoading(false);
        }
        setIsLoading(false);
      })

      .catch((error) => console.error(error));
  };

  const columns1 = [
    // background-color: ${({ row }) => row.id % 2 !== 0 ? 'blue' : 'white'};

    { name: "Date", selector: (row) => row["date"], sortable: true },
    { name: "Name", selector: (row) => row["name"], sortable: true },
    { name: "Card No", selector: (row) => row["mid"], sortable: true },
    {
      name: "Loan Amount",
      selector: (row) => "₹ " + row["amount"],
      sortable: true,
    },
    {
      name: "Remaining",
      selector: (row) => "₹ " + row["remaining"],
      sortable: true,
    },
    { name: "EMI", selector: (row) => "₹ " + row["emi"], sortable: true },
    {
      name: "Interest",
      selector: (row) => "₹ " + row["interest"],
      sortable: true,
    },
    { name: "Month", selector: (row) => row["month"], sortable: true },
    {
      name: "Paid Month",
      selector: (row) => row["remaining_month"],
      sortable: true,
    },
    { name: "By", selector: (row) => row["by"], sortable: true },
    {
      name: "Delete",
      button: true,
      cell: (row) => (
        <img
          src="assets/images/bin.png"
          data-bs-toggle="modal"
          data-original-title="test"
          onClick={() => setSelectedId(row.id)}
          data-bs-target="#exampleModal"
          height={20}
          width={20}
        />
      ),
    },
  ];

  const [searchText, setSearchText] = useState("");
  const handleSearch = (e) => {
    setSearchText(e.target.value);
    // //console.log(searchText)
  };
  const filteredData = tableData.filter(
    (item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      // item.date.toString().includes(searchText) ||
      // item.amount.toString().includes(searchText) ||
      // item.remaining.toString().includes(searchText) ||
      // item.month.toString().includes(searchText) ||
      // item.emi.toString().includes(searchText) ||
      // item.remaining_month.toString().includes(searchText) ||
      // item.by.toLowerCase().includes(searchText.toLowerCase) ||
      item.mid.toString().includes(searchText)
  );
  const deletes = () => {
    const selectedRow = filteredData.find((row) => row.id === selectedId);
    const token = localStorage.getItem("token");
    const dObject = {
      authorization: token,
      input: {
        aid: aid,
        auth: sesspass,
        page: "loan",
        id: selectedRow.id,
      },
    };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(BASE_URL + "del_mandal_user.php", deleteData)
      // .then(response => response.json())
      .then(function (data) {
        if (data.data.message === "radha") {
          toast.success("Loan Deleted Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          Loan();
        } else {
          toast.error("Loan Not Delete", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })

      .catch((error) => console.error(error));
  };

  // //console.log(tableData)

  const margin = localStorage.getItem("margin");

  return (
    <div>
      <div class="page-body-wrapper">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">Are You Sure ?</div>
              <div class="modal-footer">
                <button
                  class="btn btn-primary"
                  type="button"
                  onClick={() => deletes()}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  class="btn btn-dark"
                  type="button"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <div class="page-body" style={{ marginLeft: "320px" }}>
          <div class="container-fluid">
            <div class="col-sm-12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                <Link
                  class="btn btn-primary"
                  style={{ textAlign: "center", marginRight: "10px" }}
                  to={"/dhan_loan_emi"}
                >
                  Regular EMI
                </Link>
                {/* <Link class="btn btn-primary" style={{ textAlign: "center", marginRight: "10px" }} to={"/bulk_emi"}>
                                    Bulk EMI
                                </Link> */}
              </div>
              <div class="card" style={{ minHeight: "250px" }}>
                <div class="card-header pb-0">
                  <h5>Dhanvarsha Loans</h5>
                  {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                </div>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div class="card-body">
                      <div class="table-responsive">
                        <DataTable
                          columns={columns1}
                          data={filteredData}
                          pagination
                          subHeader
                          subHeaderComponent={
                            <input
                              type="text"
                              placeholder="Search"
                              value={searchText}
                              style={{ width: "200px" }}
                              class="form-control"
                              onChange={handleSearch}
                            />
                          }
                          striped
                          highlightOnHover
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DhanLoan;
