import React from "react";
import Header from "./header";

import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import $ from "jquery";
import { BASE_URL } from "./config";

const LoanEmi = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [visible, setVisible] = useState(false);
  const [loanid, setLoanid] = useState("");
  const [selected, setSelected] = useState(true);
  const [amount, setAmount] = useState("");

  var a_name = localStorage.getItem("user");
  var aid = localStorage.getItem("aid");
  var pass = localStorage.getItem("pass");
  const initialState = {
    member: "",
    names: "",
    card: "",
    mobile: "",
    modes: "",
    date: "",
  };
  const [selectedDate, setSelectedDate] = useState("");
  const [state, setState] = useState(initialState);
  const { member, names, card, mobile, modes, date } = state;
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [options, setOptions] = useState([]);
  const [emivalue, setEmivalue] = useState("");
  const [interest, setInterest] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    init();
    Web();
  }, []);

  var sesspass = localStorage.getItem('pass')
  var aid = localStorage.getItem('aid')
  var websess = localStorage.getItem('web')
  const web = localStorage.getItem("web")

  const Web = () => {
    const token = localStorage.getItem('token');
    const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { "data": encodedData }
    axios
      .post(BASE_URL + "web_auth.php", deleteData)
      .then(function (data) {
        const abc = data.data.web
        if (abc !== web) {
          localStorage.setItem('user', '')
          localStorage.setItem('token', '')
          localStorage.setItem('pass', '')
          localStorage.setItem('aid', '')
          localStorage.setItem('margin', '')
          localStorage.setItem('web', '')
          toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
          window.location.reload(false);

        }
        //console.log(data)
      }
      )
      .catch(error => console.error(error));
  }



  const init = () => {
    const token = localStorage.getItem('token');
    const dObject = { authorization: token, input: { "page": "AddEmi" } };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { "data": encodedData }
    axios
      .post(BASE_URL + "loanemi_user.php", deleteData)
      // .then(response => response.json())
      .then((data) => {
        const optionsData = data.data.result.map((item) => ({
          value:
            item.id +
            "-" +
            item.loan_type +
            "-" +
            item.emi +
            "-" +
            item.name +
            "-" +
            item.remaining +
            "-" +
            item.lid,
          label:
            item.id +
            ")  " +
            item.name +
            "   [" +
            item.remaining +
            "/" +
            item.amount +
            "]",
        }));
        setOptions(optionsData);
      })
      .catch((error) => console.error(error));
  };

  const Loans = (option) => {
    // setSelectedOption(option.value);

    const aa = option.value.split("-");
    setSelectedOption(aa[0])
    //console.log(selectedOption)
    setTableData(aa[2]);
    setLoanid(aa[5]);

    setEmivalue(aa[1]);
    setSelectedDate(aa[0] + ")  " + aa[3]);
    setAmount(aa[4]);

    setVisible("true");
  };

  let name, value;
  const handler = (e) => {
    name = e.target.name;
    value = e.target.value;
    setState({ ...state, [name]: value });
  };

  const interestcal = (e) => {
    const i = e.target.value;
    setInterest(i);
    const mul = i * amount;

    const iamount = Math.round(mul / 100);

    setSelectedValue(iamount);
    const totalamount = parseInt(tableData) + parseInt(iamount);
    setTotal(totalamount);
  };

  const Check = (e) => {
    $(".btn").addClass("disabled");

    let f_total = "";

    e.preventDefault();
    if (emivalue === "OLD") {
      f_total = total;
      // setTotal(tableData)
    } else {
      f_total = tableData;
    }
    const token = localStorage.getItem('token');
    const dObject = {
      authorization: token, input: {
        mid: selectedOption,
        admin_name: a_name,
        trans_type: "EMI",
        aid: aid,
        auth: pass,
        emi: tableData,
        emi_interest: selectedValue,
        loan_id: loanid,
        emi_type: emivalue,
        total: f_total,
        payment_mode: modes,
        percent: interest,
        date: date,
      }
    };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { "data": encodedData }
    axios
      .post(BASE_URL + "admin_add_transaction.php", deleteData)
      .then(function (data) {
        //console.log(data);
        if (data.data.message == "radha") {
          setTableData("");
          setTotal("");
          setSelectedValue("");
          setSelectedDate("");
          setState({ modes: "", date: "" });
          setInterest("");
          toast.success("Transaction successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          $(".btn").removeClass("disabled");
          init();
        } else {
          toast.error("Transaction Not Done", {
            position: toast.POSITION.TOP_RIGHT,
          });
          $(".btn").removeClass("disabled");
        }
      });
  };


  const margin = localStorage.getItem("margin")

  return (
    <div>
      <div class="page-body-wrapper">
        <div class="page-body" style={{ marginLeft: "320px" }}>
          <div class="container-fluid">
            <div class="page-title">
              <ToastContainer />
              <div class="row">
                <div class="col-sm-6">
                  <h3>Loan EMI</h3>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to={"/"}>
                        <i data-feather="home"></i>Dashboard
                      </Link>
                    </li>
                    <li class="breadcrumb-item">Loan EMI</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid" style={{ paddingBottom: "300px" }}>
            <div class="select2-drpdwn">
              <div class="row">
                <div class="col-md-12" style={{ height: "300px" }}>
                  <div class="card">
                    <div class="card-header pb-0">
                      <h5 class="card-title">Loan EMI</h5>
                    </div>

                    <div class="card-body o-hidden">
                      <form onSubmit={Check}>
                        <div class="mb-2">
                          <div class="col-form-label">Loan EMI</div>

                          <Select
                            value={selectedOption}
                            onChange={Loans}
                            options={options}
                            isSearchable={true}
                          />
                        </div>
                        <div class="mb-2">
                          <h5>{selectedDate}</h5>
                        </div>

                        <>
                          <div class="mb-2">
                            <label for="name">Installment</label>
                            <input
                              class="form-control"
                              id="name"
                              type="text"
                              value={tableData}
                              name="amount"
                              onChange={handler}
                              required="required"
                              onKeyDownCapture={handleKeyPress}
                            />
                          </div>
                          <div class="mb-2">
                            <div class="name">Payment Mode</div>
                            <select
                              class="form-select col-sm-12"
                              onChange={handler}
                              value={state.modes}
                              name="modes"
                              required="required"
                              onKeyDownCapture={handleKeyPress}
                            >
                              <option value="">Select Payment Mode</option>
                              <option value="UPI">UPI</option>
                              <option value="Cash">Cash</option>
                              <option value="Bank Transfer">
                                Bank Transfer
                              </option>
                            </select>
                          </div>
                          <div class="mb-2">
                            <label for="name">Date</label>
                            <input
                              class="form-control"
                              id="name"
                              type="date"
                              name="date"
                              value={state.date}
                              onChange={handler}
                              required="required"
                              onKeyDownCapture={handleKeyPress}
                            />
                          </div>
                        </>

                        {emivalue === "OLD" && (
                          <>
                            <div class="mb-2">
                              <label for="name">Interest</label>
                              <input
                                class="form-control"
                                id="name"
                                type="text"
                                onBlur={interestcal}
                                maxLength={2}
                                name="interest"
                                onChange={handler}
                                required="required"
                                onKeyDownCapture={handleKeyPress}
                              />
                            </div>
                            <div class="mb-2">
                              <label for="name">Interest Amount</label>
                              <input
                                class="form-control"
                                id="name"
                                type="text"
                                value={selectedValue}
                                name="i_amount"
                                onChange={handler}
                                required="required"
                                onKeyDownCapture={handleKeyPress}
                              />
                            </div>
                            <div class="mb-2">
                              <label for="name">Total Amount</label>
                              <input
                                class="form-control"
                                id="name"
                                type="number"
                                value={total}
                                name="total"
                                onChange={handler}
                                required="required"
                                onKeyDownCapture={handleKeyPress}
                              />
                            </div>
                          </>
                        )}

                        <div
                          class="mt-6"
                          style={{ marginBottom: "250px !important" }}
                        >
                          <button
                            class="btn btn-primary"
                            onKeyDown={handleKeyPress}
                            type="submit"
                          >
                            Pay Installment
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default LoanEmi;

{
  /* <select class="form-control col-sm-12" id="loan" onSelect={install} name="mid"> */
}
{
  /* {data && data.map((row) => (
                                                        <input class="form-control" id="name" type="checkbox" value={row.loan_id} name="member" onChange={handler} required="required" />

                                                            // <option key={row.loan_id} value={row.emi}></option>
                                                        ))} */
}
{
  /* </select> */
}
