import React from "react";
import Header from "./header";

import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import Select from 'react-select';
import $, { error, event } from 'jquery'
import { BASE_URL } from "./config";
import DataTable from "react-data-table-component";


const Advertise = () => {

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };


    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const initialState = {
        title: "",
        desc: "",
    }

    const [state, setState] = useState(initialState)
    const { title, desc } = state;

    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [tempFileName, setTempFileName] = useState("");
    const [imageUrl, setImageUrl] = useState('');
    const [options, setOptions] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [selectedId, setSelectedId] = useState('');

    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
        setTempFileName(e.target.files[0].tmp_name);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", fileName);
        setImageUrl(URL.createObjectURL(e.target.files[0]));
        console.log(e.target.files[0])
        //console.log("done")
    };

    useEffect(() => {
        abc();
        Web();
    }, []);


    const abc = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "data": "data" } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteDatass = { "data": encodedData }
        axios
            .post(BASE_URL + 'upload.php', deleteDatass)
            .then(function (data) {
                //console.log(data)
                if (data.data.message == "radha") {
                    setTableData(data.data.results);
                }
            })
            .catch(error => console.error(error));
    }


    let name, value
    const handler = (e) => {
        name = e.target.name;
        value = e.target.value;
        setState({ ...state, [name]: value });

    };

    var a_name = localStorage.getItem('user')
    var pass = localStorage.getItem('pass')

    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": web } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                //console.log(data)
                const abc = data.data.web
                //console.log(abc + "====" + web)
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    // window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }


    const Check = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        // Create a FormData object to hold the form data
        const formData = new FormData();

        formData.append('file', file);
        formData.append('title', title);
        formData.append('desc', desc);
        formData.append('aid', aid);
        formData.append('auth', sesspass);
        formData.append('image', fileName);

        //console.log(file)
        const datas = { "image": fileName, "desc": desc, "title": title, "aid": aid, "auth": sesspass, "file": formData }

        try {
            const response = await axios.post(BASE_URL + 'advertise.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response.data.message)
            if (response.data.message === 'radha') {
                console.log('Image and data uploaded successfully.');
                // Handle any additional logic here upon successful upload
            } else {
                console.log('Something went wrong during upload.');
            }
        } catch (error) {
            console.error('Error uploading image and data:', error);
            //console.log('An error occurred while uploading.   ');
        }
    };


    const Upload = (event) => {
        event.preventDefault();

        $('.btn').addClass('disabled')
        const formData = new FormData();

        if (fileName == "") {
            toast.error('Select Image', { position: toast.POSITION.TOP_RIGHT });
            $('.btn').removeClass('disabled')
        } else {
            const token = localStorage.getItem('token');
            const dObject = { authorization: token, input: { "aid": aid, "auth": pass, "admin_name": a_name, "image": fileName, "desc": desc, "title": title, "file": file.name, "temp": tempFileName } };
            console.log("aid" + aid + "auth:" + pass + "admin_name" + a_name + "image" + fileName + "desc" + desc + "title" + title + "file" + formData + "tempFileName   --" + tempFileName)
            const encodedData = btoa(JSON.stringify(dObject));
            const deleteData = { "data": encodedData }
            axios
                .post(BASE_URL + "advertise.php", deleteData)
                .then(function (data) {
                    console.log(data)
                    if (data.data.message == "radha") {

                        toast.success('Ad Upload Successfully', { position: toast.POSITION.TOP_RIGHT });
                        $('.btn').removeClass('disabled')
                        abc();


                    } else {
                        toast.error('Something Wrong', { position: toast.POSITION.TOP_RIGHT });
                        $('.btn').removeClass('disabled')

                    }
                    $('.btn').removeClass('disabled')

                })
        }
    }


    const deletes = () => {
        const selectedRow = filteredData.find(row => row.id === selectedId);
        //console.log(selectedRow.id)
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": sesspass, "page": "ad", "id": selectedRow.id } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }

        axios
            .post(BASE_URL + "dhan_del_user.php", deleteData)
            // .then(response => response.json())
            .then(function (data) {
                //console.log(data)
                if (data.data.message === "radha") {
                    toast.success('Ad Deleted Successfully', { position: toast.POSITION.TOP_RIGHT });
                    abc();
                } else {
                    toast.error('Ad Not Delete', { position: toast.POSITION.TOP_RIGHT });

                }
            }
            )

            .catch(error => console.error(error))

    }
    const columns = [
        { name: 'Title', selector: row => row['title'], sortable: true, filterable: true },
        { name: 'Description', selector: row => row['description'], sortable: true, filterable: true },
        { name: 'Image', selector: row => row['image'], sortable: true, filterable: true, cell: row => <a href={row['image']} target="_blank" ><img src={row['image']} alt="Product" style={{ width: 50, height: 50 }} /></a>, },
        {
            name: 'Delete', button: true, cell: (row) => (
                <img src="assets/images/bin.png" data-bs-toggle="modal" data-original-title="test" onClick={() => setSelectedId(row.id)} data-bs-target="#exampleModal" height={20} width={20} />
            ),
        },
    ];
    const [searchText, setSearchText] = useState('');

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };
    const filteredData = tableData.filter((item) =>
        item.title.toLowerCase().includes(searchText.toLowerCase()) ||
        item.description.toLowerCase().includes(searchText.toLowerCase())
    );

    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="page-body" style={{ marginLeft: "320px" }}>
                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">

                                <div class="modal-body">Are You Sure ?</div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" onClick={() => deletes()} data-bs-dismiss="modal">Yes</button>
                                    <button class="btn btn-dark" type="button" data-bs-dismiss="modal">No</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="page-body"> */}
                    <div class="container-fluid">
                        <div class="page-title">
                            <div class="row">
                                <ToastContainer />

                                <div class="col-sm-6">
                                    <h3>Advertise</h3>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to={'/'}><i data-feather="home"></i></Link>Dashboard</li>
                                        <li class="breadcrumb-item">Add Advertise</li>
                                        {/* <li class="breadcrumb-item active">Add Money</li> */}
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="select2-drpdwn">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-header pb-0">
                                            <h5 class="card-title">Add Advertise</h5>
                                        </div>
                                        <div class="card-body o-hidden" enctype="multipart/form-data">
                                            <form onSubmit={Upload}>

                                                <div class="mb-2">
                                                    <label for="name">Title</label>
                                                    <input class="form-control" id="name" type="text" value={state.title} name="title" onChange={handler} required="required" onKeyDownCapture={handleKeyPress} />
                                                </div>

                                                <div class="mb-2">
                                                    <label for="name">Image</label>
                                                    <input class="form-control" name="file" id="name" onChange={saveFile} type="file" />
                                                </div>
                                                {imageUrl && <img src={imageUrl} alt="Uploaded" height={100} width={100} />}


                                                <div class="mb-2">
                                                    <label for="name">Description</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea4" value={state.desc} onChange={handler} name="desc" rows="3"></textarea>
                                                </div>
                                                <div class="mb-2">
                                                    <button class="btn btn-primary" onKeyDown={handleKeyPress} type="submit">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <DataTable
                                        columns={columns}
                                        data={filteredData}
                                        pagination
                                        subHeader

                                    />
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Advertise