import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import DataTable, { createTheme } from 'react-data-table-component';
import { BASE_URL } from "./config";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "./loader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Transaction = () => {
    const [isLoading, setIsLoading] = useState(true);

    const [selectedId, setSelectedId] = useState("");
    const [tableData, setTableData] = useState([]);
    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')

    //console.log(sesspass)

    useEffect(() => {
        Web();
        abc();
    }, []);

    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }



    const abc = () => {
        const token = localStorage.getItem('token');
        //console.log(token)
        const dObject = { authorization: token, input: { "aid": aid, "auth": sesspass, "page": "transaction" } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        //console.log(deleteData)
        axios
            .post(BASE_URL + "admin_account.php", deleteData)
            .then(function (data) {
                //console.log(data)
                if (data.data.message == "radha") {
                    setTableData(data.data.trans_result);
                    setIsLoading(false);

                }
                setIsLoading(false);

            })

            .catch(error => console.error(error))
        // setIsLoading(false);

    }



    const deletes = () => {

        const selectedRow = filteredData.find(row => row.id === selectedId);
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": sesspass, "page": "transaction", "id": selectedRow.id } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "del_mandal_user.php", deleteData)
            // .then(response => response.json())
            .then(function (data) {
                //console.log(data)
                if (data.data.message === "radha") {
                    toast.success('Data Deleted Successfully', { position: toast.POSITION.TOP_RIGHT });
                    abc();
                } else if (data.data.message === "FIRST") {
                    toast.error('First Transaction can not delete', { position: toast.POSITION.TOP_RIGHT });

                } else {
                    toast.error('Data Not Delete', { position: toast.POSITION.TOP_RIGHT });

                }
            }
            )

            .catch(error => console.error(error))

    }
    const coolor = "Withdraw"
    const conditionalRowStyles = [
        {
            // columns: 'Type',
            when: row => row.trans_type === coolor, // Replace coolor with your condition
            style: {
                backgroundColor: '#ea0b3596',
                borderRadius: '15px',
                margin: '7px' // Set the background color for matching rows
            },
        },
        {
            // columns: 'Type',
            when: row => row.trans_type !== coolor, // Replace coolor with your condition
            style: {
                backgroundColor: '#c3e0b8',
                borderRadius: '15px',
                margin: '7px' // Set the background color for non-matching rows
            },
        },
        {
            columns: 'Type',
            when: row => row.trans_type == "EMI" || row.trans_type == "Added Interest" || row.trans_type == "Withdraw Interest" || row.trans_type == "Return Interest", // Replace coolor with your condition
            style: {
                backgroundColor: '#76b3df',
                borderRadius: '15px',
                margin: '7px' // Set the background color for non-matching rows
            },
        },
        {
            columns: 'Type',
            when: row => row.trans_type == "Late Payment Charge", // Replace coolor with your condition
            style: {
                backgroundColor: '#ffce009e',
                borderRadius: '15px',
                margin: '7px' // Set the background color for non-matching rows
            },
        },
    ];
    const columns = [
        { name: 'Date', selector: row => row['date'], sortable: true, filterable: true, width: '120px' },
        {
            name: 'Type',
            selector: row => row['trans_type'],
            sortable: true,
            filterable: true,
            width: '150px',
            cell: (row) => (
                <div
                    className="badge"
                    style={{
                        backgroundColor:
                            row.trans_type === "coolor"
                                ? "#ea0b3596"
                                : row.trans_type === "Regular"
                                    ? "#8B0000"
                                    : row.trans_type === "EMI" || row.trans_type === "Added Interest" || row.trans_type === "Withdraw Interest" || row.trans_type === "Return Interest" || row.trans_type === "Latejoin Interest"
                                        ? "#76b3df"
                                        : row.trans_type === "Interest"
                                            ? "#d325bc94"
                                            :row.trans_type === "CAPITAL INTEREST"
                                            ? "#a4ae1b94"
                                            : row.trans_type === "Late Payment Charge"
                                                ? "#ffce009e"
                                                : "gray",
                    }}
                >
                    {row['trans_type']}
                </div>
            ),
        },
        { name: 'Card No', selector: row => row['mid'], sortable: true, filterable: true, width: '60px' },
        { name: 'Type', selector: row => row['name'], sortable: true, filterable: true, width: '250px' },
        { name: 'Amount', selector: row => "₹ " + row['regular_installment'], sortable: true, filterable: true, width: '100px' },
        { name: 'Payment Mode', selector: row => row['payment_mode'], sortable: true, filterable: true, width: '120px' },
        { name: 'By', selector: row => row['by'], sortable: true, filterable: true, width: '150px' },
        { name: 'Total', selector: row => "₹ " + row['total'], sortable: true, filterable: true, style: { fontWeight: 'bold' }, width: '100px' },
        { name: 'Remark', selector: row => row['remark'], sortable: true, filterable: true, width: '250px' },
        {
            name: 'Delete', button: true, width: '80px', cell: (row) => (
                row.emi_type !== 'FIRST' &&
                <img src="assets/images/bin.png" data-bs-toggle="modal" data-original-title="test" onClick={() => setSelectedId(row.id)} data-bs-target="#exampleModal" height={20} width={20} />
            ),
        },
    ];
    
    const [searchText, setSearchText] = useState('');





    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };
    const filteredData = tableData.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        // item.date.toString().includes(searchText) ||
        item.mid.toString().includes(searchText) ||
        item.trans_type.toString().includes(searchText.toLowerCase())
        // item.by.toLowerCase().includes(searchText.toLowerCase()) ||
        // item.emi.toString().includes(searchText) ||
        // item.emi_interest.toString().includes(searchText) ||
        // item.total.toString().includes(searchText) ||
        // item.payment_mode.toLowerCase().includes(searchText.toLowerCase()) ||
        // item.emi_type.toLowerCase().includes(searchText.toLowerCase())
    );


    // const conditionalRowStyles = (row) => {
    //     // You can use the row index to determine if it's even or odd
    //     const index = filteredData.indexOf(row);
    //     return index % 2 === 0 ? { backgroundColor: 'green' } : { backgroundColor: 'red' };
    //   };
    const customStyles = {
        cells: {
            style: {}, // Add common styles for all cells in the 'Type' column if needed
            classes: 'badge badge-primary', // Apply the class to all cells in the 'Type' column
        },
    };

    const margin = localStorage.getItem("margin")

    return (
        <div>

            <div class="page-body-wrapper">
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">

                            <div class="modal-body">Are You Sure ?</div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" onClick={() => deletes()} data-bs-dismiss="modal">Yes</button>
                                <button class="btn btn-dark" type="button" data-bs-dismiss="modal">No</button>

                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
                <div class="page-body" style={{ marginLeft: "320px" }}>


                    <div class="container-fluid">
                        <div class="col-sm-12 ">
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "20px" }}>
                                <Link class="btn btn-primary" style={{ textAlign: "center", marginRight: "10px" }} to={"/addmoney"}>
                                    Regular Installment
                                </Link>
                                <Link class="btn btn-primary" style={{ textAlign: "center", marginRight: "10px" }} to={"/mandal_user_bulk_emi"}>
                                    Bulk Installment
                                </Link>
                                <Link class="btn btn-primary" style={{ textAlign: "center" }} to={"/withdraw"}>
                                    Withdraw
                                </Link>
                            </div>



                            <div class="card" style={{ minHeight: "250px" }}>
                                <div class="card-header pb-0">
                                    <h5>Transactions</h5><span></span>
                                </div>
                                {isLoading ? (
                                    <Loader />
                                ) : (<>
                                    <div class="card-body">
                                        <div class="table-responsive">

                                            <DataTable
                                                columns={columns}
                                                data={filteredData}
                                                pagination
                                                subHeader
                                                // conditionalRowStyles={conditionalRowStyles}
                                                // conditionalCellStyles={conditionalRowStyles}
                                                // customStyles={customStyles}
                                                subHeaderComponent={
                                                    <input type="text" placeholder="Search" class="form-control" style={{ width: "200px" }} value={searchText} onChange={handleSearch} />
                                                }

                                            />
                                        </div>
                                    </div>

                                </>
                                )}
                            </div>

                        </div>
                    </div>



                </div>

            </div>

        </div>



    )
}

export default Transaction;