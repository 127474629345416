import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { BASE_URL } from "./config";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const [mobile, setMobile] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [errors, setErrors] = useState("");
  const router = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    if (mobile.length > 10) {
      toast.error("Provide 10 digit Mobile number");

    } 
      setMobile(e.target.value);
    
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  if(localStorage.getItem("aid") != "" && localStorage.getItem("aid") != null){
    router.push("/account");
  }


  const Checkuser = (e) => {
    e.preventDefault();

    if (!mobile || !password) {
      toast.error("Please Provide Mobile number and Password.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const dObject = {
        authorization: "",
        input: { mobile: mobile, password: password },
      };
      const encodedData = btoa(JSON.stringify(dObject));
      const deleteData = { data: encodedData };
      console.log(deleteData)
      axios
        .post(BASE_URL + "admin_login.php", deleteData)
        .then(function (data) {
          console.log(data)
          if (
            data.data.message === "Invalid User or password" ||
            data.data.message === "Token verification failed" ||
            data.data.message === "Invalid password format" ||
            data.data.message === "Password is incorrect"
          ) {
            toast.error("Invalid Mobile number and Password.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsLoading(false);

          } else {
            toast.success("Successfully Logged In....", {
              position: toast.POSITION.TOP_RIGHT,
            });
            localStorage.setItem("user", data.data.data.data.admin_name);
            localStorage.setItem("token", data.data.data.token);
            localStorage.setItem("pass", data.data.data.data.admin_code);
            localStorage.setItem("aid", data.data.data.data.admin_id);
            localStorage.setItem("web", data.data.data.data.admin_web);
            localStorage.setItem("type", data.data.data.data.admin_type);
            localStorage.setItem("time", data.data.data.eat);
            setTimeout(() => history.push("/account"), 100);
            setIsLoading(false);

            // window.location.reload(false);
          }
        });
    }
  };

  const change = () => {
    setTimeout(() => history.push("/"), 0);
    // window.location.reload(false);
  };

  return (
    <div>
      <body>
        <div class="container-fluid p-0">
          <div class="row m-0">
            <div class="col-12 p-0">
              <div class="login-card">
                <div>
                  <ToastContainer />

                  {/* <button class="btn btn-primary btn-center" onClick={() => history.push("/mylogin")} >Ecommerce Login </button> */}
                  <div class="logo">
                    <img
                      style={{ height: "150px", width: "150px" }}
                      src="../assets/images/MLOGO.png"
                      alt="Mangalik Gold"
                    />
                    <h3>Mandal Login</h3>
                  </div>
                  <div class="login-main">
                    <form class="theme-form" onSubmit={Checkuser}>
                      <div class="form-group">
                        <label class="col-form-label">Mobile No</label>
                        <input
                          class="form-control"
                          type="number"
                          value={mobile}
                          maxLength={10}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required=""
                          placeholder="Please Enter Mobile No"
                        />
                      </div>
                      <div class="form-group">
                        <label class="col-form-label">Password</label>
                        <div class="form-input position-relative">
                          <input
                            class="form-control"
                            type="text"
                            value={password}
                            onChange={(e) => {
                              handlePasswordChange(e);
                            }}
                            placeholder="Please Enter Password"
                          />
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        {isLoading == false ? (
                          <div class="text-end mt-3">
                            <input
                              type="submit"
                              class="btn btn-primary btn-block w-100"
                              value="Login"
                            />
                          </div>
                        ) : (
                          <div class="text-end mt-3">
                            <input
                              type="submit"
                              class="btn btn-primary btn-block w-100 disabled"
                              value="Login"
                            />
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </body>
    </div>
  );
};

export default Login;
