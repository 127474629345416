import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import Header from "./header";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import $ from 'jquery'
import { BASE_URL } from "./config";


const Profile = () => {

    const [tableData, setTableData] = useState([])
    const [dataa, setDataa] = useState('')
    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')

    useEffect(() => {
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                aid: aid,
                auth: sesspass
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        const fetchData = async () => {
            const response = await axios.post(BASE_URL + 'get_admin.php', deleteData);
            // const data = await response.json();
            setTableData(response.data.result);
            setState({"aname": response.data.result.name,"email":response.data.result.email});

        };
        fetchData();
        Web();
    }, []);

    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }



    //console.log(tableData)

    const initialState = {
        aname: "",
        email: "",
    }

    const [state, setState] = useState(initialState)
    const { aname, email } = state;

    let name, value
    const handler = (e) => {
        name = e.target.name;
        value = e.target.value;
        setState({ ...state, [name]: value });


    };

    const Emaill = () => {
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                dataa: email,
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "admin_check_email.php", deleteData)
            .then(function (data) {
                //console.log(data.data.message);
                if (data.data.message == "same") {
                    alert("Use Different Email")
                }
            })
    }




    //console.log(email)

    const Check = (e) => {
        $('.btn').addClass('disabled');

        e.preventDefault();
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {

                aid: aid,
                auth: sesspass,
                email: email,
                a_name: aname,
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "edit_admin.php", deleteData)
            .then(function (data) {
                //console.log(data);
                setState({ aname: "", email: "" })
                toast.success('Profile Updated Successfully', { position: toast.POSITION.TOP_RIGHT });
                $('.btn').removeClass('disabled');

            })
    };

    //console.log(tableData)


    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="page-body" style={{ marginLeft: "320px" }}>
                    <div class="container-fluid">
                        <div class="page-title">
                            <ToastContainer />
                            <div class="row">
                                <div class="col-sm-6">
                                    <h3>Admin Profile</h3>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to={'/'}><i data-feather="home"></i>Dashboard</Link></li>
                                        {/* <li class="breadcrumb-item">Admin Profile</li> */}
                                        <li class="breadcrumb-item active">Admin Profile</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="select2-drpdwn">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-header pb-0">
                                            <h5 class="card-title">Admin Profile</h5>
                                        </div>
                                        <div class="card-body o-hidden">
                                            <form onSubmit={Check}>
                                                <div class="mb-2">
                                                    <label for="name">Admin Name</label>
                                                    <input class="form-control" id="name" type="text" name="aname" value={state.aname}  onChange={handler} required="required" />
                                                </div>
                                                <div class="mb-3">
                                                    <label for="contact">Admin Email</label>
                                                    <input class="form-control" type="text" name="email" value={state.email}  onBlur={Emaill} onChange={handler} />
                                                </div>
                                                <div class="mb-2">
                                                    <input class="btn btn-primary" type="submit" value="Update Profile" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Profile      