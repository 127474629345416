import React from "react";
import Header from "./header";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import Select from 'react-select';
import $, { event } from 'jquery'
import { BASE_URL } from "./config";


const SakhiAddmoney = () => {
    const [selectedOption, setSelectedOption] = useState("");

    const [options, setOptions] = useState([]);

    const [selectedDate, setSelectedDate] = useState('');

    const handleDateChange = (options) => {
        setSelectedDate(options.value);
        var id = options.value.split(")")
        setSelectedOption(id[0])
    };
   var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    const initialState = {
        installment: "",
        modes: "",
        date: ""
    }

    const [state, setState] = useState(initialState)
    const { installment, modes, date } = state;

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        abab();
        Web();
    }, []);

    const abab = () => {
        const token = localStorage.getItem('token');
        //console.log(token)
        const dObject = { authorization: token, input: { "page": "SakhiAddEmi" } };
        //console.log(dObject)
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        //console.log(deleteData)
        axios
            .post(BASE_URL + 'admin_select_user.php', deleteData)
            .then((data) => {
                //console.log(data)
                const optionsData = data.data.result.map((item) => ({ value: item.id + ")  " + (item.name), label: item.id + ")  " + (item.name) }));
                setOptions(optionsData);
            })
            .catch(error => console.error(error));

    }

    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess} };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        //console.log(deleteData)
        axios
            .post(BASE_URL + "web_auth.php",deleteData)
            .then(function (data) {
                const abc = data.data.web
              if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }



    let name, value
    const handler = (e) => {
        name = e.target.name;
        value = e.target.value;
        setState({ ...state, [name]: value });

    };

    var a_name = localStorage.getItem('user')
    var aid = localStorage.getItem('aid')
    var pass = localStorage.getItem('pass')
    //console.log(pass)



    const Check = (event) => {

        event.preventDefault();
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: {
            "payment_mode": modes,
            "sid": selectedOption,
            "regular_installment": installment,
            "admin_name": a_name,
            "aid": aid,
            "auth": pass,
            "total": installment,
            "trans_type": "REGULAR",
            "date": date
        } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }

        if (modes == "" || modes == "Select Payment Mode") {
            toast.error('Select Payment Mode', { position: toast.POSITION.TOP_RIGHT });
        } else {
            $('.btn').addClass('disabled')

            axios
                .post(BASE_URL + "admin_sakhi_add_transaction.php", deleteData)
                .then(function (data) {
                    //console.log(data)
                    if (data.data.message == "radha") {
                        setState({ installment: "", date: "", modes: "" })
                        setSelectedDate("")
                        toast.success('Transaction Successfully', { position: toast.POSITION.TOP_RIGHT });
                        $('.btn').removeClass('disabled')

                    } else {
                        toast.error('Transaction Not Done', { position: toast.POSITION.TOP_RIGHT });
                        $('.btn').removeClass('disabled')

                    }

                })
        }

    };


    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="page-body" style={{ marginLeft: "320px" }}>
                    <div class="container-fluid">
                        <div class="page-title">
                            <div class="row">
                                <ToastContainer />

                                <div class="col-sm-6">
                                    <h3>Sakhi Saheli Add Money</h3>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to={'/'}><i data-feather="home"></i>Dashboard</Link></li>
                                        <li class="breadcrumb-item">Sakhi Add Money</li>
                                        {/* <li class="breadcrumb-item active">Add Money</li> */}
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="select2-drpdwn">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-header pb-0">
                                            <h5 class="card-title">Sakhi Add Money</h5>
                                        </div>
                                        <div class="card-body o-hidden">
                                            <form onSubmit={Check}>

                                                <div class="mb-2">
                                                    <div class="col-form-label">Select Name</div>
                                                    <Select
                                                        value={selectedDate}
                                                        onChange={handleDateChange}
                                                        options={options}
                                                        isSearchable={true}

                                                    />


                                                </div>
                                                <div class="mb-2">
                                                    <p>{selectedDate}</p>
                                                </div>
                                                <div class="mb-2">
                                                    <label for="name">Regular Installment</label>
                                                    <input class="form-control" id="name" type="text" value={200} disabled name="installment" onChange={handler} required="required" onKeyDownCapture={handleKeyPress} />
                                                </div>


                                                <div class="mb-2">
                                                    <div class="name">Payment Mode</div>
                                                    <select class="form-select col-sm-12" onChange={handler} value={state.modes} name="modes" required="required" onKeyDownCapture={handleKeyPress}>
                                                        <option value="">Select Payment Mode</option>
                                                        <option value="UPI">UPI</option>
                                                        <option value="Cash">Cash</option>
                                                        <option value="Bank Transfer">Bank Transfer</option>
                                                    </select>
                                                </div>
                                                <div class="mb-2">
                                                    <label class="name">Date</label>
                                                    <input class="form-control" type="date" name="date" onChange={handler} value={state.date} placeholder="Select Date" required="required" onKeyDownCapture={handleKeyPress} />
                                                </div>

                                                <div class="mb-2">
                                                    <button class="btn btn-primary" onKeyDown={handleKeyPress} type="submit">ADD MONEY</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SakhiAddmoney