import React from "react";
import Header from "./header";

import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import Select from 'react-select';
import $ from 'jquery'
import { BASE_URL } from "./config";

const Create_dhan_user = () => {


    const [selectedOption, setSelectedOption] = useState("");

    const [options, setOptions] = useState([]);
    const [phone, setPhone] = useState("")

    const [selectedDate, setSelectedDate] = useState('');

    const handleDateChange = (options) => {
        setSelectedDate(options.value);
        var id = options.value.split(")  ")
        //console.log(id[1])
        setSelectedOption(id[0])
        var am = id[1].split("-");
        setTableData(id[1])
        setDatas(am[1])
        setPhone(am[1])

    };


    const [open, setOpen] = useState(false);
    const [datas, setDatas] = useState("")

    const handleClose = () => {
        setOpen(false);
    };


    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };


    const initialState = {
        member: "",
        names: "",
        amount: "",
        mobile: ""
    }

    const [state, setState] = useState(initialState)
    const { member, names, amount, mobile } = state;

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                page: "CreateMember"

            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + 'admin_select_user.php', deleteData).then(data => {
                //console.log(data)
                const optionsData = data.data.result.map((item) => ({ value: item.id + ")  " + (item.name) + "-" + item.phone, label: item.name + "  (" + (item.phone) + ")" }));
                setOptions(optionsData);
            })
            .catch(error => console.error(error));
        Web()
    }, []);

    let name, value
    const handler = (e) => {
        name = e.target.name;
        value = e.target.value;
        setState({ ...state, [name]: value });

    };

    var a_name = localStorage.getItem('user')
    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: { "aid": aid, "auth": websess } };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }


    const Check = (e) => {
        e.preventDefault();


        $('.btn').addClass('disabled');
        //console.log(selectedOption)
        if (selectedOption == "") {
            toast.error('Please Select User', { position: toast.POSITION.TOP_RIGHT });
            $('.btn').removeClass('disabled');

        } else {
            const token = localStorage.getItem('token');
            const dObject = {
                authorization: token, input: {

                    uid: selectedOption,
                    name: names,
                    aid: aid,
                    auth: sesspass,
                    amount: amount,
                    phone: datas
                }
            };
            const encodedData = btoa(JSON.stringify(dObject));
            const deleteData = { "data": encodedData }
            axios
                .post(BASE_URL + "admin_dhan_user.php", deleteData)
                .then(function (data) {
                    if (data.data.message === "radha") {
                        toast.success('Member Created.', { position: toast.POSITION.TOP_RIGHT });
                        setTableData("")
                        setDatas("");
                        alert('New Member Id : ' + data.data.id)
                        setState({ member: "", card: "", amount: "", names: "" })
                        $('.btn').removeClass('disabled');

                    } else {
                        toast.error('Member Not Created.', { position: toast.POSITION.TOP_RIGHT });
                        $('.btn').removeClass('disabled')

                    }

                })
        }
    };

    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="page-body" style={{ marginLeft: "320px" }}>
                    <div class="container-fluid">
                        <div class="page-title">
                            <ToastContainer />
                            <div class="row">
                                <div class="col-sm-6">
                                    <h3>Create Dhan User</h3>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to={'/'}><i data-feather="home"></i>Dashboard</Link></li>
                                        <li class="breadcrumb-item">Create Dhan User</li>
                                        {/* <li class="breadcrumb-item active">Create Member</li> */}
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="select2-drpdwn">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-header pb-0">
                                            <h5 class="card-title">Create Dhan User</h5>
                                        </div>
                                        {/* <Modal
                                            onClose={handleClose}
                                            open={open}
                                            style={{
                                                position: 'absolute',
                                                borderRadius: '5px',
                                                backgroundColor: '#E92A2E',
                                                height: 110,
                                                width: 350,
                                                margin: 'auto',
                                                color: 'white',
                                                fontSize: '6px !importnat',
                                                padding: '15px',
                                    
                                            }}
                                        >
                                            {datas && (<h2>New Created Member Id Is {datas}</h2>)}
                                        </Modal> */}
                                        <div class="card-body o-hidden">
                                            <form onSubmit={Check}>

                                                {/* <input class="form-control" type="text" onChange={(e) => setPname(e.target.value)} onBlur={search} /> */}
                                                <div class="mb-2">
                                                    <div class="col-form-label">Select User</div>
                                                    <Select
                                                        value={tableData}
                                                        onChange={handleDateChange}
                                                        options={options}
                                                        isSearchable={true}

                                                    />


                                                </div>
                                                <p>{tableData}</p>

                                                <div class="mb-2">
                                                    <label for="name">Name</label>
                                                    <input class="form-control" id="names" type="text" value={state.names} name="names" onChange={handler} required="required" onKeyDownCapture={handleKeyPress} />
                                                </div>
                                                {/* <div class="mb-2">
                                                    <label for="name">Mobile</label>
                                                    <input class="form-control" id="phone" type="number" value={datas} name="mobile" onChange={handler} required="required" onKeyDownCapture={handleKeyPress} />
                                                </div> */}
                                                <div class="mb-2">
                                                    <label for="name">Amount</label>
                                                    <input class="form-control" id="amount" type="text" value={state.amount} name="amount" onChange={handler} required="required" onKeyDownCapture={handleKeyPress} />
                                                </div>


                                                <div class="mb-2">
                                                    <button class="btn btn-primary" onKeyDown={handleKeyPress} type="submit">Create User</button>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}


export default Create_dhan_user