import React from "react";
import Header from "./header";

import axios from "axios";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import $ from 'jquery'
import { BASE_URL } from "./config";

const Goldprice = () => {


    const [tableData, setTableData] = useState();

    const [state, setState] = useState([{
        fourc: "",
        twoc: "",
        eighteenc: "",
        silver: ""
    }])
    const { fourc, twoc, eighteenc, silver } = state;

    let name, value
    const handler = (e) => {
        name = e.target.name;
        value = e.target.value;
        setState({ ...state, [name]: value });

    };


    var aid = localStorage.getItem('aid')

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };
    useEffect(() => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: {"data": "data"} };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        const fetchData = async () => {
            const response = await axios.post(BASE_URL + 'get_gold_price.php', deleteData);
            //console.log(response.data)
            setState({ ...state, fourc: response.data.result.gold_a, eighteenc: response.data.result.gold_b, twoc: response.data.result.gold_c, silver: response.data.result.silver });

        };
        fetchData();
        Web();
    }, []);

    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                aid: aid,
                auth: websess,
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
            }
            )
            .catch(error => console.error(error));
    }



    //console.log(state)
    const InsertData = (e) => {
        $('.btn').addClass('disabled');

        e.preventDefault();

        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                gold_a: fourc,
                aid: aid,
                gold_b: twoc,
                gold_c: eighteenc,
                silver: silver
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "set_gold_price.php", deleteData)
            .then(function (data) {
                //console.log(data);
                if (data.data.message == "radha") {
                    setState({ fourc: "", twoc: "", eighteenc: "", silver: "" })
                    toast.success('Prices Updated Successfully', { position: toast.POSITION.TOP_RIGHT });
                    $('.btn').removeClass('disabled');

                } else {
                    toast.error('Price Not updated', { position: toast.POSITION.TOP_RIGHT });
                    $('.btn').removeClass('disabled')

                }


            })
    };


    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="page-body" style={{ marginLeft: "320px" }}>
                    <div class="container-fluid">
                        <div class="page-title">
                            <ToastContainer />
                            <div class="row">
                                <div class="col-sm-6">
                                    <h3>Gold Price</h3>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to={'/'}><i data-feather="home"></i></Link>Dashboard</li>
                                        <li class="breadcrumb-item">Gold Price</li>
                                        {/* <li class="breadcrumb-item active">Gold Price</li> */}
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="select2-drpdwn">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-header pb-0">
                                            <h5 class="card-title">Gold Price</h5>
                                        </div>
                                        <div class="card-body o-hidden">
                                            <form onSubmit={InsertData}>


                                                <div class="mb-2">
                                                    <label for="name">Gold Price (24 C)</label>
                                                    <input class="form-control" value={fourc} id="name" type="text" name="fourc" onChange={handler} onKeyDownCapture={handleKeyPress} />
                                                </div>
                                                <div class="mb-2">
                                                    <label for="name">Gold Price (22 C)</label>
                                                    <input class="form-control" value={twoc} id="name" type="text" name="twoc" onChange={handler} onKeyDownCapture={handleKeyPress} />
                                                </div>
                                                <div class="mb-2">
                                                    <label for="name">Gold Price (18 C)</label>
                                                    <input class="form-control" value={eighteenc} id="name" type="text" name="eighteenc" onChange={handler} onKeyDownCapture={handleKeyPress} />
                                                </div>
                                                <div class="mb-2">
                                                    <label for="name">Silver Ornaments</label>
                                                    <input class="form-control" id="name" value={state.silver} type="text" name="silver" onChange={handler} onKeyDownCapture={handleKeyPress} />
                                                </div>


                                                <div class="mb-2">
                                                    <button class="btn btn-primary" onKeyDown={handleKeyPress} type="submit">UPDATE PRICE</button>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Goldprice