import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Header from "./header";
import { ToastContainer, toast } from "react-toastify";

import DataTable from 'react-data-table-component';
import { BASE_URL } from "./config";

import Loader from "./loader";

const Listemploye = () => {
    const [isLoading, setIsLoading] = useState(true);


    const [tableData, setTableData] = useState([]);
    const [selectedId, setSelectedId] = useState("")
    const [editId, setEditId] = useState("")
    const [userpass, setUserpass] = useState("");


    useEffect(() => {
        abc();
        Web();
    }, []);

    const abc = () => {
        const token = localStorage.getItem('token');
        const dObject = { authorization: token, input: {} };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "admin_employe_list.php", deleteData)
            .then(function (data) {
                setTableData(data.data.result)
                setIsLoading(false);

            })
    }

    var sesspass = localStorage.getItem('pass')
    var aid = localStorage.getItem('aid')
    var websess = localStorage.getItem('web')
    const web = localStorage.getItem("web")

    const Web = () => {
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                aid: aid,
                auth: websess,
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "web_auth.php", deleteData)
            .then(function (data) {
                const abc = data.data.web
                if (abc !== web) {
                    localStorage.setItem('user', '')
                    localStorage.setItem('token', '')
                    localStorage.setItem('pass', '')
                    localStorage.setItem('aid', '')
                    localStorage.setItem('margin', '')
                    localStorage.setItem('web', '')
                    toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
                    window.location.reload(false);

                }
                //console.log(data)
            }
            )
            .catch(error => console.error(error));
    }




    const columns = [
        // { name: 'ID', selector: 'mid', sortable: true },
        { name: 'Name', selector: 'name', sortable: true },
        // { name: 'Name', selector: 'trans_type', sortable: true },
        // { name: 'Email', selector: 'email', sortable: true },
        { name: 'Mobile', selector: 'mobile', sortable: true },
        {
            name: 'Edit', button: true, cell: (row) => (
                <img src="assets/images/pen.png" data-bs-toggle="modal" data-original-title="test" onClick={() => setEditId(row.id)} data-bs-target="#exampleModal" height={20} width={20} />
            ),
        },
        {
            name: 'Delete', button: true, cell: (row) => (
                <img src="assets/images/bin.png" data-bs-toggle="modal" data-original-title="test" onClick={() => setSelectedId(row.id)} data-bs-target="#exampleModal" height={20} width={20} />
            ),
        },
    ];

    //console.log(editId)
    //console.log(selectedId)
    //console.log(tableData)
    const [searchText, setSearchText] = useState('');

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        //console.log(searchText)
    };
    const filteredData = tableData.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        // item.email.toLowerCase().includes(searchText.toLowerCase()) ||
        item.mobile.toString().includes(searchText)

    );

    const Edits = () => {
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                id: editId,
                status: "EDIT",
                pass: userpass
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "employe_action.php", deleteData)
            .then(function (data) {
                if (data.data.message == "radha") {
                    toast.success('Password Updated Successfully', { position: toast.POSITION.TOP_RIGHT });
                    setUserpass("")
                    setEditId("")
                    abc();

                } else {
                    toast.error('Password Not Updated', { position: toast.POSITION.TOP_RIGHT });

                }
            }
            )
            .catch(error => console.error(error));
    }

    const Deletes = () => {
        const token = localStorage.getItem('token');
        const dObject = {
            authorization: token, input: {
                id: selectedId,
                status: "DELETE",
            }
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const deleteData = { "data": encodedData }
        axios
            .post(BASE_URL + "employe_action.php", deleteData)
            .then(function (data) {
                if (data.data.message == "radha") {
                    toast.success('Employe Deleted Successfully', { position: toast.POSITION.TOP_RIGHT });
                    setSelectedId("")
                    abc();

                } else {
                    toast.error('Employe Not Deleted', { position: toast.POSITION.TOP_RIGHT });

                }
            }
            )
            .catch(error => console.error(error));
    }

    const margin = localStorage.getItem("margin")

    return (
        <div>
            <div class="page-body-wrapper">
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            {editId ? (
                                <>
                                    <div class="modal-body">Update Password</div>
                                    <input type="number" name="datapas" onChange={(e) => setUserpass(e.target.value)} class="form-control mb-4" style={{ border: "1px solid gray", width: "300px", marginLeft: "15px" }} />
                                    <div class="modal-footer">
                                        <button class="btn btn-primary" type="button" onClick={Edits} data-bs-dismiss="modal">Update</button>
                                        <button class="btn btn-dark" type="button" data-bs-dismiss="modal">No</button>
                                    </div>
                                </>
                            ) : (<>
                                <div class="modal-body">Are you sure?You want to delete</div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" onClick={Deletes} data-bs-dismiss="modal">Yes</button>
                                    <button class="btn btn-dark" type="button" data-bs-dismiss="modal">No</button>
                                </div>
                            </>)}
                        </div>
                    </div>
                </div>
                <ToastContainer />
                <div class="page-body" style={{ marginLeft: "320px" }}>
                    {/* <ToastContainer /> */}
                    <div class="container-fluid">
                        <div class="col-sm-12">
                            <div class="card" style={{ minHeight: "250px" }}>
                                <div class="card-header pb-0">
                                    <h5>Employes</h5><span></span>
                                </div>
                                {isLoading ? (
                                    <Loader />
                                ) : (<>
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <DataTable
                                                columns={columns}
                                                data={filteredData}
                                                pagination
                                                subHeader
                                                subHeaderComponent={
                                                    <input type="text" placeholder="Search" class="form-control" style={{width:"200px"}} value={searchText} onChange={handleSearch} />
                                                }

                                            />
                                        </div>
                                    </div>
                                </>)}
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default Listemploye;